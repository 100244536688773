import React,{useEffect} from 'react';
import './Dec6_AboutUs_Mission_CSS.css';
import {Dec6_AboutUs_Mission_Block_Cont_MH,Dec6_AboutUs_Mission_Block_Cont_P} from '../TextFiles/Dec6_AboutUs_TextFile';
import Aos from 'aos';
import 'aos/dist/aos.css'; 

function Dec6_AboutUs_Mission() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []); 

    return(
        <div data-aos="flip-right" data-aos-duration="2000"
            data-aos-once = "true" data-aos-easing="ease-in-out-cubic" 
            className="Dec6_AboutUs_Mission_Block">
            <div data-aos="fade-down"
                    data-aos-delay="70"
                    data-aos-once = "true"
                    className="Dec6_AboutUs_Mission_Block_Cont">
                <h4 data-aos="fade-up"
                    data-aos-delay="150"
                    data-aos-once = "true"
                    className="Dec6_AboutUs_Mission_Block_Cont_MH">
                    {Dec6_AboutUs_Mission_Block_Cont_MH}
                </h4>
                <p data-aos="flip-left"
                    data-aos-delay="250"
                    data-aos-duration="6000"
                    data-aos-easing="ease-in-out" 
                    data-aos-once = "true"
                    className="Dec6_AboutUs_Mission_Block_Cont_P">
                    {Dec6_AboutUs_Mission_Block_Cont_P}
                </p>
            </div>
        </div>
    );
}

export default Dec6_AboutUs_Mission;