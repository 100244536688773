import React from 'react'
import Blog2_BgImage from './Blog2_BgImage'
import Blog2_cat from './Blog2_cat'
import HeaderLine from '../../Header_RedLine/Dec6_HeaderRL';
import ResponsiveNavbar from '../../ResponsiveNavbar/Dec6_Navbar';
import AboveFooter from '../../AboveFooter/Dec6_AboveFooter';
import Footer from '../../Footer/Dec6_Footer';
import Scroll_To_Top from '../../Scroll_To_Top/Dec6_STTop';
import {Helmet} from "react-helmet";
  
export default  function Blog2(){
     return(
<>
<Helmet>
            <title>Insights | Dec6 Consulting Pvt Ltd</title>
            <meta name="description" content="Dec6 Consulting Private Limited" />
            <link rel="canonical" href="https://www.dec6.ca/Insights" />
        </Helmet>
        <Scroll_To_Top/>
        <HeaderLine/>
        <ResponsiveNavbar/>
<Blog2_BgImage/>
<Blog2_cat/>
<AboveFooter/>
        <Footer/>
</>
     )
 }