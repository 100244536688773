import React,{useEffect} from 'react';
import './Dec6_Home_WWA_CSS.css';
import {Dec6_WWA_Items_P_Text} from '../TextFiles/Dec6_Home_TextFiles';
import Aos from 'aos';
import 'aos/dist/aos.css'; 

function Dec6_Home_WWA() {
    return(
        <div className="Dec6_WWA_Items">
            <div className="Dec6_WWA_Items_Div">
                <p data-aos="fade-down"
                        data-aos-delay="120"
                        data-aos-once = "true"
                        className="Dec6_WWA_Items_P1">
                    {Dec6_WWA_Items_P_Text[0]}
                </p>
                <p data-aos="flip-left"
                        data-aos-delay="200"
                        data-aos-once = "true"
                        data-aos-duration="2000"
                        className="Dec6_WWA_Items_P2">
                    {Dec6_WWA_Items_P_Text[1]}
                </p>
                {/*     <hr data-aos="fade-up"
                        data-aos-delay="300"
                        data-aos-once = "true"
                        className="Dec6_WWA_Items_HR"></hr>  */}
            </div>
        </div>
    );
}

export default Dec6_Home_WWA;