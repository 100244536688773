import React,{useEffect} from 'react';
import './Dec6_AboutUs_Purpose_CSS.css';
import {Dec6_AboutUs_Purpose_Block_Cont_AH_Text,Dec6_AboutUs_Purpose_Block_Cont_MH,Dec6_AboutUs_Purpose_Block_Cont_P} from '../TextFiles/Dec6_AboutUs_TextFile';
import Aos from 'aos';
import 'aos/dist/aos.css';

function Dec6_AboutUs_Purpose() {
    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);

    return(
        <div data-aos="fade-down" className="Dec6_AboutUs_Purpose_Block">
            <div data-aos="fade-up" data-aos-delay="30" data-aos-easing="ease-in-sine" className="Dec6_AboutUs_Purpose_Block_Cont">
             {/*   <h3 data-aos="fade-left" data-aos-delay="50" className="Dec6_AboutUs_Purpose_Block_Cont_AH">
                    {Dec6_AboutUs_Purpose_Block_Cont_AH_Text}
                </h3>  */}
                <h4 data-aos="fade-right" data-aos-delay="130" className="Dec6_AboutUs_Purpose_Block_Cont_MH">
                    {Dec6_AboutUs_Purpose_Block_Cont_MH}
                </h4>
                <p data-aos="flip-up"
                    data-aos-delay="250"
                    data-aos-duration="2000"
                    data-aos-easing="ease-in-out-cubic" className="Dec6_AboutUs_Purpose_Block_Cont_P">
                    {Dec6_AboutUs_Purpose_Block_Cont_P}
                </p>
            </div>
        </div>
    );
}

export default Dec6_AboutUs_Purpose;