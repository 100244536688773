import React from 'react';
import './Dec6_JoinUs_BgImage_CSS.css';

function Dec6_JoinUs_BgImage() {
    return(
        <div className="Dec6_JoinUs_BgImage_Block">
            <div className="Dec6_JoinUs_BgImage_Block_Line">
                <h3 className="Dec6_JoinUs_BgImage_Block_Line_H">
                    Join the expedition
                </h3>
                <p className="Dec6_JoinUs_BgImage_Block_Line_P">
                We're on a mission, to help customers, communities and businesses overcome barriers and realise their potential.
                </p>
            </div>
        </div>
    );
}

export default Dec6_JoinUs_BgImage;