import React from 'react'
import './Dec6_TAC_CSS.css';
import {Grid} from '@material-ui/core';
import {Dec6_TAC_MH,Dec6_TAC_H,Dec6_TAC_P,Dec6_TAC_P_List,Dec6_TAC_P_List1} from '../TextFiles/Dec6_TAC_TextFile';

const Dec6_TAC = () => {
    return (
        <div className="Dec6_TAC_Div">

        <Grid container xs={12} className="Dec6_TAC_Grid">
            <Grid item lg={12} sm={12} md={12} xs={12} className="Dec6_TAC_Grid1 Dec6_TAC_Grid1_MH">
                {Dec6_TAC_MH}
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="Dec6_TAC_Grid1 Dec6_TAC_Grid_P">
                {Dec6_TAC_H[0]}
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="Dec6_TAC_Grid1 Dec6_TAC_Grid_P">
                Hello! <b>‘You’</b> refers to you as a user browsing 
                <a className="Dec6_TAC_Grid_Link" href="https://www.dec6.ca/"> https://www.dec6.ca/</a> or user of the Services.
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="Dec6_TAC_Grid1 Dec6_TAC_Grid_P">
                These Terms of Service (“<strong>Terms</strong>”) govern your use of the DEC6 Consulting Private Limited (“<strong>DEC6</strong>”) website (“<strong>Site</strong>”) and any DEC6 mobile application, application programming interfaces, and other services offered by DEC6. 
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="Dec6_TAC_Grid1 Dec6_TAC_Grid_P">
                {Dec6_TAC_P[0]}
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="Dec6_TAC_Grid1 Dec6_TAC_Grid_P Dec6_TAC_Grid_H">
                {Dec6_TAC_H[1]}
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="Dec6_TAC_Grid1 Dec6_TAC_Grid_P">
                {Dec6_TAC_P[1]}
                {Dec6_TAC_P[2]}
                {Dec6_TAC_P[3]}
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="Dec6_TAC_Grid1 Dec6_TAC_Grid_P Dec6_TAC_Grid_H">
                {Dec6_TAC_H[2]}
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="Dec6_TAC_Grid1 Dec6_TAC_Grid_P">
                Some of our Services have additional terms and conditions (“<strong>Additional Terms</strong>”).  Where Additional Terms apply to a Service, we will make them available for you to read through your use of that Service.  By using that Service, you agree to the Additional Terms.
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="Dec6_TAC_Grid1 Dec6_TAC_Grid_P Dec6_TAC_Grid_H">
                {Dec6_TAC_H[3]}
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="Dec6_TAC_Grid1 Dec6_TAC_Grid_P">
                {Dec6_TAC_P[4]}
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="Dec6_TAC_Grid1 Dec6_TAC_Grid_P">
                <ul className="Dec6_TAC_Grid_List">
                    <li>
                        {Dec6_TAC_P_List[0]}
                    </li>
                    <li>
                        {Dec6_TAC_P_List[1]}
                    </li>
                    <li>
                        {Dec6_TAC_P_List[2]}
                    </li>
                    <li>
                        {Dec6_TAC_P_List[3]}
                    </li>
                    <li>
                        {Dec6_TAC_P_List[4]}
                    </li>
                    <li>
                        {Dec6_TAC_P_List[5]}
                    </li>
                    <li>
                        {Dec6_TAC_P_List[6]}
                    </li>
                    <li>
                        {Dec6_TAC_P_List[7]}
                    </li>
                    <li>
                        {Dec6_TAC_P_List[8]}
                    </li>
                    <li>
                        {Dec6_TAC_P_List[9]}
                    </li>
                    <li>
                        {Dec6_TAC_P_List[10]}
                    </li>
                    <li>
                        {Dec6_TAC_P_List[11]}
                    </li>
                </ul>
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="Dec6_TAC_Grid1 Dec6_TAC_Grid_P Dec6_TAC_Grid_H">
                {Dec6_TAC_H[4]}
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="Dec6_TAC_Grid1 Dec6_TAC_Grid_P">
                The domain address <a className="Dec6_TAC_Grid_Link" href="https://www.dec6.ca/"> https://www.dec6.cacluding/ </a> is owned by DEC6. Unless stated otherwise, all content present on this Site, including all the software, text, images, graphics, video and audio used on this Site, is exclusive and proprietary material owned by DEC6. The DEC6 name and logo are trademarks which belong to and are owned by DEC6 Consulting Private Limited. These trademarks may not be used in any manner whatsoever without prior written consent from DEC6 Consulting Private Limited.
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="Dec6_TAC_Grid1 Dec6_TAC_Grid_P Dec6_TAC_Grid_H">
                {Dec6_TAC_H[5]}
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="Dec6_TAC_Grid1 Dec6_TAC_Grid_P">
                {Dec6_TAC_P[5]}
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="Dec6_TAC_Grid1 Dec6_TAC_Grid_P Dec6_TAC_Grid_H">
                {Dec6_TAC_H[6]}
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="Dec6_TAC_Grid1 Dec6_TAC_Grid_P">
                {Dec6_TAC_P[6]}
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="Dec6_TAC_Grid1 Dec6_TAC_Grid_P Dec6_TAC_Grid_H">
                {Dec6_TAC_H[7]}
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="Dec6_TAC_Grid1 Dec6_TAC_Grid_P">
                {Dec6_TAC_P[7]}
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="Dec6_TAC_Grid1 Dec6_TAC_Grid_P Dec6_TAC_Grid_H">
                {Dec6_TAC_H[8]}
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="Dec6_TAC_Grid1 Dec6_TAC_Grid_P">
                {Dec6_TAC_P[8]}
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="Dec6_TAC_Grid1 Dec6_TAC_Grid_P Dec6_TAC_Grid_H">
                {Dec6_TAC_H[9]}
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="Dec6_TAC_Grid1 Dec6_TAC_Grid_P">
                <ul className="Dec6_TAC_List1">
                    <li>
                        {Dec6_TAC_P[9]}
                    </li>
                    <li>
                        {Dec6_TAC_P[10]}
                    </li>
                    <li>
                        {Dec6_TAC_P[11]}
                    </li>
                    <li>
                        {Dec6_TAC_P[12]}
                    </li>
                </ul>
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="Dec6_TAC_Grid1 Dec6_TAC_Grid_P Dec6_TAC_Grid_H">
                {Dec6_TAC_H[10]}
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="Dec6_TAC_Grid1 Dec6_TAC_Grid_P">
                {Dec6_TAC_P[13]}
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="Dec6_TAC_Grid1 Dec6_TAC_Grid_P Dec6_TAC_Grid_H">
                {Dec6_TAC_H[11]}
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="Dec6_TAC_Grid1 Dec6_TAC_Grid_P">
                <ul className="Dec6_TAC_List1">
                    <li>
                        {Dec6_TAC_P_List1[0]}
                    </li>
                    <li>
                        {Dec6_TAC_P_List1[1]}
                    </li>
                    <li>
                        {Dec6_TAC_P_List1[2]}
                    </li>
                    <li>
                        {Dec6_TAC_P_List1[3]}
                    </li>
                    <li>
                        {Dec6_TAC_P_List1[4]}
                    </li>
                </ul>
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="Dec6_TAC_Grid1 Dec6_TAC_Grid_P Dec6_TAC_Grid_H">
                {Dec6_TAC_H[12]}
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="Dec6_TAC_Grid1 Dec6_TAC_Grid_P">
                <ul className="Dec6_TAC_List1">
                    <li>
                        {Dec6_TAC_P[14]}
                    </li>
                    <li>
                        For any concerns and issues w.r.t. to the content of this site or otherwise, please connect with us on our <a className="Dec6_TAC_Grid_Link" href="mailto:info@dec6.ca"> email </a>.
                    </li>
                </ul>
            </Grid>
        </Grid>
        </div>
    )
}

export default Dec6_TAC

