import React,{useState} from 'react';
import styled from 'styled-components';
import PMenu from './Dec6_PMenu';
import HamBurger from './Dec6_HamBurger';
import HeaderLine from '../Header_RedLine/Dec6_HeaderRL';
import './Dec6_Navbar_CSS.css';
import Dec6_Logo from '../Images/Dec6Logo.svg';
import {NavLink} from "react-router-dom";

function Dec6_Navbar() {

  const [Dec6_Navbar_Items1,setNavbar] = useState(false);

    const changeBackground = () => {
        if(window.scrollY >= 20)
        {
            setNavbar(true);
        }
        else {
            setNavbar(false);
        }
    };

    window.addEventListener('scroll',changeBackground);
  return(
    /*  <nav className="Dec6_Navbar_Items1">   */
        <nav className={Dec6_Navbar_Items1 ? 'Dec6_Navbar_Items1 active' : 'Dec6_Navbar_Items1'}> 
          <div className="Dec6_Navbar_Items1_Image_Div">
            <NavLink className="Dec6_Footer_Link" exact to="/">
              <img src={Dec6_Logo} className="Dec6_Navbar_Items1_Image" alt="Dec6 Consulting Private Limited" />
            </NavLink>
          </div>
          <PMenu/>
          <HamBurger/>  
      </nav>
  );
}

export default Dec6_Navbar