import HeaderLine from '../Header_RedLine/Dec6_HeaderRL';
import AboutUs_Navbar from '../AboutUs_Navbar/Dec6_AboutUs_Navbar';
import Navbar from '../Navbar/Dec6_Navbar';
import ResponsiveNavbar from '../ResponsiveNavbar/Dec6_Navbar';
import JoinUs_BgImage from '../JoinUs_BgImage/Dec6_JoinUs_BgImage';
import JoinUs_ECS2 from '../JoinUs_ExploreCareerSection2/Dec6_JoinUs_ECS2';
import JoinUs_ECS_Blocks from '../JoinUs_ExploreCareerSection_3Blocks/Dec6_JoinUs_ECS_New1';
import JoinUs_FEB from '../JoinUs_FreshExpBlock/Dec6_JoinUs_FEB';
import AboveFooter from '../AboveFooter/Dec6_AboveFooter';
import Footer from '../Footer/Dec6_Footer';
import Scroll_To_Top from '../Scroll_To_Top/Dec6_STTop';
import {Helmet} from "react-helmet";

function Dec6_JoinUsPage() {
  return (
      <>
          <Helmet>
            <title>JoinUs | Dec6 Consulting Pvt Ltd</title>
            <meta name="description" content="Explore Career Opportunities" />
            <link rel="canonical" href="https://www.dec6.ca/JoinUs" />
          </Helmet>
        <Scroll_To_Top/>
        <HeaderLine/>
        <ResponsiveNavbar/>   
        <JoinUs_BgImage/>
        <JoinUs_ECS2/>
        <JoinUs_ECS_Blocks/>
        <JoinUs_FEB/> 
        <AboveFooter/>
        <Footer/>
      </>
  );
}

export default Dec6_JoinUsPage;