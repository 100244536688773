import HeaderLine from '../Header_RedLine/Dec6_HeaderRL';
import Navbar from '../Navbar/Dec6_Navbar';
import ResponsiveNavbar from '../ResponsiveNavbar/Dec6_Navbar';
import ContactUs_BS from '../ContactUs_4BlockSection/Dec6_ContactUs_4BS';
import ContactUs_WTCP from '../ContactUs_WantToContactUsPart/Dec6_ContactUs_WTCU';
import ContactUs_Map from '../ContactUs_GoogleMap/Dec6_ContactUs_Map';
import AboveFooter from '../AboveFooter/Dec6_AboveFooter';
import Footer from '../Footer/Dec6_Footer';
import Scroll_To_Top from '../Scroll_To_Top/Dec6_STTop';
import {Helmet} from "react-helmet";

function Dec6_ContactUsPage() {
  return (
      <>
        <Helmet>
            <title>Contact Us | Dec6 Consulting Pvt Ltd</title>
            <meta name="description" content="Dec6 Consulting Private Limited" />
            <link rel="canonical" href="https://www.dec6.ca/ContactUs" />
        </Helmet>
        <Scroll_To_Top/>
        <HeaderLine/>
        <ResponsiveNavbar/>   
        <ContactUs_WTCP/>
        <ContactUs_BS/>
        {/* <ContactUs_Map/>      */}
        <AboveFooter/>
        <Footer/>
      </>
  );
}

export default Dec6_ContactUsPage;