import React,{Component, useState} from 'react'
import {Dec6_Home_6BoxGL_MainBox_H_Text, Dec6_Home_6BoxGL_MainBox_P_Text, Dec6_Home_6BoxGL_ReadMore_P_Text} from '../TextFiles/Dec6_Home_TextFiles';
import Aos from 'aos';
import OutsideClickHandler from 'react-outside-click-handler';
import 'aos/dist/aos.css';
import Dialog from './DialogBox';
import HCDialog from './HC_DialogBox';
import REDialog from './RE_DialogBox';
import IDialog from './Ins_DialogBox';
import AAMDialog from './AAM_DialogBox';
import ADDialog from './AD_DialogBox';
import './Dec6_DB_6BGL_CSS.css';
import {Container,Grid} from "@material-ui/core";

class Dec6_DB_6BGL extends Component {

      state = {
        isOpen: false,
        isHcOpen: false,
        isREOpen: false,
        isIOpen: false,
        isADOpen: false,
        isAAMOpen: false
    } 

    render() {
        return (
            <Grid container center sm={12} xs={12} className="Dec6_6BGL_MainBox_BottomLine" ref={node => { this.node = node; }}>
            <Grid container  sm={12} xs={12} className="Dec6_6BGL_MainBox">
                <Grid item sm={4} xs={12} data-aos="fade-left" data-aos-once = "true" className="Dec6_6BGL_MainBox_Box1">
                   
                    <i className="fas fa-shopping-cart iconglb"></i>
                    
                    <h3  className="Dec6_6BGL_MainBox_H">{Dec6_Home_6BoxGL_MainBox_H_Text[0]}</h3>
                    <p  className="Dec6_6BGL_MainBox_P">
                            {!this.state.isOpen?Dec6_Home_6BoxGL_MainBox_P_Text[0]:Dec6_Home_6BoxGL_ReadMore_P_Text[0]}
                    </p>
                  
                    <OutsideClickHandler onOutsideClick={() => {this.setState({ isOpen: false})}}>
                        {/* {!this.state.isOpen? */}
                        <button className="Dec6_6BGL_MainBox_Button" onClick={(e) => this.setState({ isOpen: !this.state.isOpen })}>{!this.state.isOpen?'Read More':'Back'}</button>
                    </OutsideClickHandler>
                    {/* <Dialog isOpen={this.state.isOpen} onClose={(e) => this.setState({ isOpen: false})}>
                        {Dec6_Home_6BoxGL_ReadMore_P_Text[0]}
                    </Dialog> */}
                </Grid>
                <Grid item sm={4} item xs={12} data-aos="fade-down" data-aos-once = "true" className="Dec6_6BGL_MainBox_Box1">
                    <i className="fas fa-clinic-medical iconglb"></i>
                   
                    <h3  className="Dec6_6BGL_MainBox_H">{Dec6_Home_6BoxGL_MainBox_H_Text[1]}</h3>
                    <p  className="Dec6_6BGL_MainBox_P">
                             {!this.state.isHcOpen?Dec6_Home_6BoxGL_MainBox_P_Text[1]:Dec6_Home_6BoxGL_ReadMore_P_Text[1]} 
                    </p>
                    <OutsideClickHandler onOutsideClick={() => {this.setState({ isHcOpen: false})}}>

                        <button className="Dec6_6BGL_MainBox_Button" onClick={(e) => this.setState({ isHcOpen: !this.state.isHcOpen })}>{!this.state.isHcOpen?'Read More':'Back'}</button>
                    </OutsideClickHandler>
                    {/* <HCDialog isHCOpen={this.state.isHcOpen} onClose={(e) => this.setState({ isHcOpen: false})}>
                        {Dec6_Home_6BoxGL_ReadMore_P_Text[1]}
                    </HCDialog> */}
                </Grid>
                <Grid sm={4} item xs={12} data-aos="fade-right" data-aos-once = "true" className="Dec6_6BGL_MainBox_Box1">
                    <i className="fas fa-recycle iconglb"></i>
                   
                   
                    <h3  className="Dec6_6BGL_MainBox_H">{Dec6_Home_6BoxGL_MainBox_H_Text[2]}</h3>
                    <p  className="Dec6_6BGL_MainBox_P">
                            {!this.state.isREOpen?Dec6_Home_6BoxGL_MainBox_P_Text[2]:Dec6_Home_6BoxGL_ReadMore_P_Text[2]}
                    </p>
                    <OutsideClickHandler onOutsideClick={() => {this.setState({ isREOpen: false})}}>
                        <button className="Dec6_6BGL_MainBox_Button" onClick={(e) => this.setState({ isREOpen: !this.state.isREOpen })}>{!this.state.isREOpen?'Read More':'Back'}</button>
                    </OutsideClickHandler>
                    {/* <REDialog isREOpen={this.state.isREOpen} onClose={(e) => this.setState({ isREOpen: false})}>
                        {Dec6_Home_6BoxGL_ReadMore_P_Text[2]}
                    </REDialog> */}
                </Grid>
                <Grid sm={4} item xs={12} data-aos="fade-left" data-aos-once = "true" className="Dec6_6BGL_MainBox_Box1">
                    <i className="fas fa-hand-holding-heart iconglb"></i>
                   
                    <h3  className="Dec6_6BGL_MainBox_H">{Dec6_Home_6BoxGL_MainBox_H_Text[3]}</h3>
                    <p  className="Dec6_6BGL_MainBox_P">
                            {!this.state.isIOpen?Dec6_Home_6BoxGL_MainBox_P_Text[3]:Dec6_Home_6BoxGL_ReadMore_P_Text[3]}
                    </p>
                    <OutsideClickHandler onOutsideClick={() => {this.setState({ isIOpen: false})}}>
                        <button className="Dec6_6BGL_MainBox_Button" onClick={(e) => this.setState({ isIOpen: !this.state.isIOpen })}>{!this.state.isIOpen?'Read More':'Back'}</button>
                    </OutsideClickHandler>
                    {/* <IDialog isIOpen={this.state.isIOpen} onClose={(e) => this.setState({ isIOpen: false})}>
                        {Dec6_Home_6BoxGL_ReadMore_P_Text[3]}
                    </IDialog> */}
                </Grid>
                <Grid sm={4} item xs={12} data-aos="fade-up" data-aos-once = "true" className="Dec6_6BGL_MainBox_Box1">
                    <i className="fas fa-space-shuttle iconglb"></i>
                  <h3  className="Dec6_6BGL_MainBox_H">{Dec6_Home_6BoxGL_MainBox_H_Text[4]}</h3>
                    <p  className="Dec6_6BGL_MainBox_P">
                    {!this.state.isADOpen?Dec6_Home_6BoxGL_MainBox_P_Text[4]:Dec6_Home_6BoxGL_ReadMore_P_Text[4]}
                    </p>
                    <OutsideClickHandler onOutsideClick={() => {this.setState({ isADOpen: false})}}>
                        <button className="Dec6_6BGL_MainBox_Button" onClick={(e) => this.setState({ isADOpen: !this.state.isADOpen })}>{!this.state.isADOpen?'Read More':'Back'}</button>
                    </OutsideClickHandler>
                    {/* <ADDialog isADOpen={this.state.isADOpen} onClose={(e) => this.setState({ isADOpen: false})}>
                        {Dec6_Home_6BoxGL_ReadMore_P_Text[4]}
                    </ADDialog> */}
                </Grid>
                <Grid sm={4} item xs={12} data-aos="fade-right" data-aos-once = "true" className="Dec6_6BGL_MainBox_Box1">
                    <i className="fas fa-plane-departure iconglb"></i>
                    <h3  className="Dec6_6BGL_MainBox_H">{Dec6_Home_6BoxGL_MainBox_H_Text[5]}</h3>
                    <p  className="Dec6_6BGL_MainBox_P">
                    {!this.state.isAAMOpen?Dec6_Home_6BoxGL_MainBox_P_Text[5]:Dec6_Home_6BoxGL_ReadMore_P_Text[5]}
                    </p>
                    <OutsideClickHandler onOutsideClick={() => {this.setState({ isAAMOpen: false})}}>

                       <button className="Dec6_6BGL_MainBox_Button" onClick={(e) => this.setState({ isAAMOpen: !this.state.isAAMOpen })}>{!this.state.isAAMOpen?'Read More':'Back'}</button>
                    </OutsideClickHandler>
                    {/* <AAMDialog isAAMOpen={this.state.isAAMOpen} onClose={(e) => this.setState({ isAAMOpen: false})}>
                        {Dec6_Home_6BoxGL_ReadMore_P_Text[5]}
                    </AAMDialog> */}
                </Grid>
            </Grid>
        </Grid>
        )
    }
}

export default Dec6_DB_6BGL
