import HeaderLine from '../Header_RedLine/Dec6_HeaderRL';
import AboutUs_Navbar from '../AboutUs_Navbar/Dec6_AboutUs_Navbar';
import Navbar from '../Navbar/Dec6_Navbar';
import ResponsiveNavbar from '../ResponsiveNavbar/Dec6_Navbar';
import Insights_BgImage from '../Insights_BgImage/Dec6_Insights_BgImage';
import Insights_6GPC from '../Insights_6GridPaperComp/Dec6_Insights_6GPC';
import AboveFooter from '../AboveFooter/Dec6_AboveFooter';
import Footer from '../Footer/Dec6_Footer';
import Scroll_To_Top from '../Scroll_To_Top/Dec6_STTop';
import {Helmet} from "react-helmet";
import Blog from '../Blog/Blog';

function Dec6_Blog() {
  return (
      <>
        <Helmet>
            <title>Insights | Dec6 Consulting Pvt Ltd</title>
            <meta name="description" content="Dec6 Consulting Private Limited" />
            <link rel="canonical" href="https://www.dec6.ca/Insights" />
        </Helmet>
        <Scroll_To_Top/>
        <HeaderLine/>
        <ResponsiveNavbar/>
        <Blog/>
        <AboveFooter/>
        <Footer/>
      </>
  );
}

export default Dec6_Blog;