import React,{Component} from 'react'
import '../MainCSS.css'
import './Responsive_DialogBox.css';

let dialogStyles = {
    width: '330px',
    maxWidth: '100%',
    height: '205px',
    margin: '0 auto',
    position: 'fixed',
    left: '50%',
    top: '73%',
    transform: 'translate(-50%,-50%)',
    zIndex: '999',
    backgroundColor: '#eee',
    padding: '10px 20px 40px',
    padding: '10px',
    paddingTop: '5px',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'var(--fontfamily)'
}

let dialogCloseButtonStyles = {
    width: '30px',
    height: '20px',
    fontWeight: 'bold',
    fontSize: '1rem',
    marginBottom: '15px',
    padding: '3px 8px',
    cursor: 'pointer',
    backgroundColor: '#eee',
  /*  borderRadius: '8px',   */
    border: 'none',  
    alignSelf: 'flex-end',
    color: '#cc0000'
}

let paragraphStyles = {
    marginBottom: '15px',
    marginTop: '8px',
    alignSelf: 'flex-start',
    lineHeight: '16px'
}

let HeadingStyles = {
    fontSize: '1.2rem',
    marginTop: '-32px',
    alignSelf: 'flex-start',
    color: '#cc0000',
    fontWeight: 'bold'
}

class DialogBox extends Component {
    render() {

        let dialog = (
            <div className="DialogBox_Div">
                <button className="DialogBox_Div_Button" style={dialogCloseButtonStyles} onClick={this.props.onClose}>X</button>
          {/*      I am a dialog  */}
                <div  style={HeadingStyles}>E-Commerce</div>
                <div style={paragraphStyles} className="P_Style">{this.props.children}</div>
            </div>
        )

        if(! this.props.isOpen) {
            dialog = null;
        }

        return (
            <div>
                {dialog}
            </div>
        );
    }
}


export default DialogBox
