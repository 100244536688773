
import React, {useState} from 'react';
import {FaArrowCircleUp} from 'react-icons/fa';
import './Dec6_STTop_CSS.css';
import "../MainCSS.css";
import {Grid} from '@material-ui/core';

const Dec6_STTop  = () =>{

  const [showScroll, setShowScroll] = useState(false)

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 300){
      setShowScroll(true)
    } else if (showScroll && window.pageYOffset <= 300){
      setShowScroll(false)
    }
  };

  const scrollTop = () =>{
    window.scrollTo({top: 0, behavior: 'smooth'});
  };

  window.addEventListener('scroll', checkScrollTop)

  return (
 /*   <Grid container xs={12} alignItems='flex-end' className="Dec6_ST_Grid">
            <Grid item lg={12} sm={12} md={12} xs={12} className="Dec6_ST_Grid1">  */
      <div className="Dec6_ST_Div">
        <FaArrowCircleUp className="scrollTop" onClick={scrollTop} style={{height: 40, display: showScroll ? 'block' : 'none'}}/>
      </div>
    /*  </Grid>
    </Grid>  */
  
  );
}

export default Dec6_STTop 
