import React from 'react';
import './Dec6_JoinUs_ECS_New1_CSS.css';

function Dec6_JoinUs_ECS_New1() {
    return(
        <div className="Dec6_JoinUs_ECS_New1_Block">
            <button className="Dec6_JoinUs_ECS_New1_Block_Button1 Dec6_JoinUs_ECS_New1_Block_Button" >
                Consulting
            </button>

            <div className="Dec6_JoinUs_ECS_New1_Button2_InlBlock">
                <button className="Dec6_JoinUs_ECS_New1_Block_Button2 Dec6_JoinUs_ECS_New1_Block_Button">
                Technology
                </button>
                <div className="D6_JoinUs_ECS_New1_Block_Button2_DropDown D6_JoinUs_ECS_New1_Block_Button_DropDown">
                    <div className="D6_JoinUs_ECS_New1_Block_Button_DropDown_H">
                        <a className="D6_JoinUs_ECS_New1_Block_Button2_DropDown1" exact to="/">
                            Associate Consultant Intern
                        </a>
                    </div>
                    <div className="D6_JoinUs_ECS_New1_Block_Button_DropDown2_H">
                    <a href="https://in.indeed.com/job/associate-consultant-technology-01b59de6472f21de" className="D6_JoinUs_ECS_New1_Block_Button2_DropDown2" target="_blank">
                        Associate Consultant 
                    </a> 
                    </div>
                </div>
            </div>

            <div className="Dec6_JoinUs_ECS_New1_Button3_InlBlock">
                <button className="Dec6_JoinUs_ECS_New1_Block_Button3 Dec6_JoinUs_ECS_New1_Block_Button">
                Business Development
                </button>
                <div className="D6_JoinUs_ECS_New1_Block_Button3_DropDown D6_JoinUs_ECS_New1_Block_Button_DropDown">
                    <div className="D6_JoinUs_ECS_New1_Block_Button_DropDown_H">
                        <a className="D6_JoinUs_ECS_New1_Block_Button3_DropDown1" exact to="/">
                            Associate Consultant Intern
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Dec6_JoinUs_ECS_New1;