import React from 'react'
import HeaderLine from '../Header_RedLine/Dec6_HeaderRL';
import ResponsiveNavbar from '../ResponsiveNavbar/Dec6_Navbar';
import TAC from '../TermsAndConditionsPage/Dec6_TAC';
import AboveFooter from '../AboveFooter/Dec6_AboveFooter';
import Footer from '../Footer/Dec6_Footer';
import Scroll_To_Top from '../Scroll_To_Top/Dec6_STTop';
import {Helmet} from "react-helmet";

const Dec6_TermsandConditions = () => {
    return (
        <div>
            <Helmet>
                <title>Terms & Conditions | Dec6 Consulting Pvt Ltd</title>
                <meta name="description" content="Dec6 Consulting Private Limited" />
                <link rel="canonical" href="https://www.dec6.ca/Terms&Conditions" />
            </Helmet>
            <Scroll_To_Top/>
            <HeaderLine/>
            <ResponsiveNavbar/>
            <TAC/>
            <AboveFooter/>
            <Footer/> 
        </div>
    )
}

export default Dec6_TermsandConditions;
