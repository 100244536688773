import React from 'react';
import './Dec6_Home_BOS_CSS.css';
import Office_Image from '../Images/BOS.jpg';
import {Dec6_BOS_Items_H_Text, Dec6_BOS_Items_P_Text} from "../TextFiles/Dec6_Home_TextFiles";

function Dec6_Home_BOS() {
    return (
        <div className="Dec6_BOS_Items">
            <div className="Dec6_BOS_Items_ImageDiv">
                <img className="Dec6_BOS_Items_Image" src={Office_Image} alt="Bundle Of Services"/>
            </div>
            <div className="Dec6_BOS_Items_Heading">
                <h3 className="Dec6_BOS_Items_H">{Dec6_BOS_Items_H_Text}</h3> 
            </div>
            <div className="Dec6_BOS_Items_Para">
                <p className="Dec6_BOS_Items_Para_P">
                    {Dec6_BOS_Items_P_Text}
                </p>
            </div>
            <div className="Dec6_BOS_Items_List1">
                <ul>
                    <li>
                        <i className="fa fa-handshake-o"></i>
                        IT Consulting
                    </li>
                    <li>
                        <i className="fa fa-laptop"></i>
                        Software Engineering
                    </li>
                    <li>
                        <i className="fa fa-link"></i>
                        System Integration
                    </li>
                    <li>
                        <i className="fa fa-code"></i>
                        Web Development
                    </li>
                </ul>
            </div>
            <div className="Dec6_BOS_Items_List2">
                <ul>
                    <li>
                        <i className='fas fa-digital-tachograph'></i>
                        Digital Transformation
                    </li>
                    <li>
                        <i className='fab fa-intercom'></i>
                        Creative Design
                    </li>
                    <li>
                        <i className="fa fa-user-secret"></i>
                        Security
                    </li>
                    <li>
                        <i className="fa fa-database"></i>
                        Advanced Analytics
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default Dec6_Home_BOS;