import React from 'react';
import './Dec6_JoinUs_FEB_CSS.css';
import {Dec6_JoinUs_FEB_Block_Box_H_Text} from '../TextFiles/Dec6_JoinUs_TextFile';

const Dec6_JoinUs_FEB_Block_Box_Button_Style = {
    fontSize : '1rem',
    marginTop : '30px',
    color : 'var(--whitecolor)',
    backgroundColor : 'var(--redcolor)',
    paddingBottom : '5px',
    textAlign: 'center',
    fontWeight : '900'
}

function Dec6_JoinUs_FEB() {
    return(
        <div className="Dec6_JoinUs_FEB_Block">
            <div className="Dec6_JoinUs_FEB_Block_Box1">
                
            </div>
            <div className="Dec6_JoinUs_FEB_Block_Box2">
                <h3 className="Dec6_JoinUs_FEB_Block_Box_H">
                    {Dec6_JoinUs_FEB_Block_Box_H_Text[0]}
                </h3>
                <p className="Dec6_JoinUs_FEB_Block_Box_P">
                    Our graduate schemes are now open for applications.
                    We’ve opportunities for graduates in every area of our business so whatever your degree, you’ll find something exciting, challenging, and unexpected at Dec6. We’re constantly striving to learn and grow, and with your help, together, we can.
                </p>
                <button className="Dec6_JoinUs_FEB_Block_Box_Button1">
                    Explore featured roles
                </button>
            </div>
            <div className="Dec6_JoinUs_FEB_Block_Box3">
                <h3 className="Dec6_JoinUs_FEB_Block_Box_H">
                    {Dec6_JoinUs_FEB_Block_Box_H_Text[1]}
                </h3>
                <p className="Dec6_JoinUs_FEB_Block_Box_P">
                    We're now accepting applications to our Apprentice schemes.
                    No matter what you're looking for, we have apprenticeships for everyone. We don't have an age limit on our apprenticeships, so as long as you meet the entry requirements, we're happy to hear from you.
                </p>
                <button className="Dec6_JoinUs_FEB_Block_Box_Button1">
                    Explore featured roles
                </button>
            </div>
            <div className="Dec6_JoinUs_FEB_Block_Box4">
                
            </div>
        </div>
    );
}

export default Dec6_JoinUs_FEB;