import React, {useState, useEffect} from 'react';
import './Dec6_Home_6BoxGL_CSS.css';
import {Dec6_Home_6BoxGL_MainBox_H_Text, Dec6_Home_6BoxGL_MainBox_P_Text} from '../TextFiles/Dec6_Home_TextFiles';
import Aos from 'aos';
import 'aos/dist/aos.css';
import {Container,Grid} from "@material-ui/core";


function ReadMore({ children, maxCharacterCount=100}) {
    const text= children;
  
    const [isTruncated, setIsTruncated] = useState(true);
  
    const resultString = isTruncated ? text.slice(0, maxCharacterCount) : text;
  
    function toggleIsTruncated() {
      setIsTruncated(!isTruncated);
    }
  
    return(
      <p className="has-text-left">
        {resultString}
          <span onClick={toggleIsTruncated} className="Dec6_Home_6BoxGL_MainBox_P_Button">
          {isTruncated ? " >>>" : "<<<"}
          </span>
        </p>
    );
  }


function Dec6_Home_6BoxGL(props) {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []);


    return(
        <>
    {/*    <div className="Dec6_Home_6BoxGL_MainBox_BottomLine">
            <div className="Dec6_Home_6BoxGL_MainBox"> */}

    <Container>
        <Grid container justify="center" alignItems="center" fixed className="Dec6__6BG_Grid">
               
   {/*     <div className="Dec6_Industries_B2B_Cont_MainBlock">    */}
        <Grid item lg={4} sm={12} md={4} xs={12} className="Dec6_6BG_Grid1">
                <div data-aos="fade-left" data-aos-once = "true" className="Dec6_Home_6BoxGL_MainBox_Box1">
                    <i className="fas fa-shopping-cart iconglb"></i>
                    <h3 className="Dec6_Home_6BoxGL_MainBox_H">{Dec6_Home_6BoxGL_MainBox_H_Text[0]}</h3>
                    <p className="Dec6_Home_6BoxGL_MainBox_P">
                        <ReadMore maxCharacterCount={330}>
                            {Dec6_Home_6BoxGL_MainBox_P_Text[0]}
                        </ReadMore>
                    </p>
                </div>
            </Grid>

            <Grid item lg={4} sm={12} md={4} xs={12} className="Dec6_6BG_Grid1">
                <div data-aos="fade-down" data-aos-once = "true" className="Dec6_Home_6BoxGL_MainBox_Box2">
                    <i className="fas fa-clinic-medical iconglb"></i>
                    <h3 className="Dec6_Home_6BoxGL_MainBox_H">{Dec6_Home_6BoxGL_MainBox_H_Text[1]}</h3>
                    <p className="Dec6_Home_6BoxGL_MainBox_P">
                        <ReadMore maxCharacterCount={334}>
                            {Dec6_Home_6BoxGL_MainBox_P_Text[1]}
                        </ReadMore>
                    </p>
                </div>
            </Grid>

            <Grid item lg={4} sm={12} md={4} xs={12} className="Dec6_6BG_Grid1">
                <div data-aos="fade-right" data-aos-once = "true" className="Dec6_Home_6BoxGL_MainBox_Box3">
                    <i className="fas fa-recycle iconglb"></i>
                    <h3 className="Dec6_Home_6BoxGL_MainBox_H">{Dec6_Home_6BoxGL_MainBox_H_Text[2]}</h3>
                    <p className="Dec6_Home_6BoxGL_MainBox_P">
                        <ReadMore maxCharacterCount={324}>
                            {Dec6_Home_6BoxGL_MainBox_P_Text[2]}
                        </ReadMore>
                    </p>
                </div>
            </Grid>

            <Grid item lg={4} sm={12} md={4} xs={12} className="Dec6_6BG_Grid1">
                <div data-aos="fade-left" data-aos-once = "true" className="Dec6_Home_6BoxGL_MainBox_Box4">
                    <i className="fas fa-hand-holding-heart iconglb"></i>
                    <h3 className="Dec6_Home_6BoxGL_MainBox_H">{Dec6_Home_6BoxGL_MainBox_H_Text[3]}</h3>
                    <p className="Dec6_Home_6BoxGL_MainBox_P">
                        <ReadMore maxCharacterCount={155}>
                            {Dec6_Home_6BoxGL_MainBox_P_Text[3]}
                        </ReadMore>
                    </p>
                </div>
            </Grid>

            <Grid item lg={4} sm={12} md={4} xs={12} className="Dec6_6BG_Grid1">
                <div data-aos="fade-up" data-aos-once = "true" className="Dec6_Home_6BoxGL_MainBox_Box5">
                    <i className="fas fa-space-shuttle iconglb"></i>
                    <h3 className="Dec6_Home_6BoxGL_MainBox_H">{Dec6_Home_6BoxGL_MainBox_H_Text[4]}</h3>
                    <p className="Dec6_Home_6BoxGL_MainBox_P">
                        <ReadMore maxCharacterCount={153}>
                            {Dec6_Home_6BoxGL_MainBox_P_Text[4]}
                        </ReadMore>
                    </p>
                </div>
            </Grid>

            <Grid item lg={4} sm={12} md={4} xs={12} className="Dec6_6BG_Grid1">
                <div data-aos="fade-right" data-aos-once = "true" className="Dec6_Home_6BoxGL_MainBox_Box6">
                    <i className="fas fa-plane-departure iconglb"></i>
                    <h3 className="Dec6_Home_6BoxGL_MainBox_H">{Dec6_Home_6BoxGL_MainBox_H_Text[5]}</h3>
                    <p className="Dec6_Home_6BoxGL_MainBox_P">
                        <ReadMore maxCharacterCount={165}>
                            {Dec6_Home_6BoxGL_MainBox_P_Text[5]}
                        </ReadMore>
                    </p>
                </div>
            </Grid>
    </Grid>   
    </Container>  
   {/* </div>
        </div>
            <div >
                <hr className="Dec6_Home_6BoxGL_HR"></hr>
            </div>   */}
        </>    
    );
}

export default Dec6_Home_6BoxGL;