import React from 'react';
import './Blog2_BgImage_CSS.css';
import { Grid, Container, Typography } from "@material-ui/core";

function Blog2_BgImage() {
    return(
        <Grid container justify="center" alignItems="center" className="Dec6_BlogG_Grid">
        <Grid item lg={12} sm={12} md={12} xs={12} className="Dec6_BlogG_Grid1"  >
        <div className="Dec6_Industries_BgImage_Block_Blog2">
        

        </div>
        </Grid>
        </Grid>
    );
}

export default Blog2_BgImage;