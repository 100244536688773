import React from 'react';
import {NavLink} from "react-router-dom";
import './Dec6_Menu_CSS.css';
import {Dec6_Navbar_Items1_List2_Text} from '../TextFiles/Dec6_Home_TextFiles';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';


import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

const Dec6_PMenu = ({ open }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };
      const handleClose = () => {
        setAnchorEl(null);
      };
    return (
        <nav className="Dec6_Navba1">
        <ul className="Dec6_Navbar_Items1_List2">
            <li>
                <NavLink exact activeClassName="active" className="Dec6_Navbar_Items1_List2_Link_Home" exact to="/">
                    {Dec6_Navbar_Items1_List2_Text[0]}
                </NavLink>
            </li>
            <li>
                <NavLink className="Dec6_Navbar_Items1_List2_Link_AboutUs" activeClassName="active" className="current"  exact to="/AboutUs">
                    {Dec6_Navbar_Items1_List2_Text[1]}
                </NavLink>
            </li>
            <li>
                <NavLink className="Dec6_Navbar_Items1_List2_Link_Industries" activeClassName="active" exact to="/Industries">
                    {Dec6_Navbar_Items1_List2_Text[2]}
                </NavLink>
            </li>
            <li>
                <NavLink className="Dec6_Navbar_Items1_List2_Link_OurServices" activeClassName="active" exact to="/OurServices">
                    {Dec6_Navbar_Items1_List2_Text[3]}
                </NavLink>
            </li>
           <li>
                {/* <NavLink className="Dec6_Navbar_Items1_List2_Link_Blog" activeClassName="active" exact to="/Insights">
                    {Dec6_Navbar_Items1_List2_Text[4]}
                </NavLink> */}
                <div  className='select' >

<NavLink    className='button-name' exact to="/Insights"   >
{Dec6_Navbar_Items1_List2_Text[4]}   </NavLink>
<ArrowDropDownIcon className="btn-d" onClick={(e)=>handleClick(e)}/>
</div>
<Menu
className="name_menu"

id="simple-menu"
anchorEl={anchorEl}
keepMounted
open={Boolean(anchorEl)}
onClose={handleClose}
>
<MenuItem onClick={()=>window.location.pathname='/Insights/PYTHON-DJANGO'}>PYTHON-DJANGO </MenuItem>
<MenuItem onClick={()=>window.location.pathname='/Insights/REACT/REACT-JS'}>REACT/REACT-JS</MenuItem>
<MenuItem onClick={()=>window.location.pathname='/Insights/ARTIFICIAL-INTELLIGENCE'}>ARTIFICIAL INTELLIGENCE</MenuItem>

</Menu>
            </li>    
            <li>
                <NavLink className="Dec6_Navbar_Items1_List2_Link_JoinUs" activeClassName="active" exact to="/JoinUs">
                    {Dec6_Navbar_Items1_List2_Text[5]}
                </NavLink>
            </li>
            <li>
                <NavLink className="Dec6_Navbar_Items1_List2_Link_ContactUs" activeClassName="active" exact to="/ContactUs">
                    {Dec6_Navbar_Items1_List2_Text[6]}
                </NavLink>
            </li>
        </ul>  
        </nav>
    )
}

export default Dec6_PMenu