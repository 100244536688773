import React from "react";
import {Paper,Container,Typography,Box,Grid} from "@material-ui/core";
// import HIcon from '../../assets/Images/heading-icon.svg';
import "../MainCSS.css";
import "./Blog_cat.css"

const Blog_cat = () => {
  return (
    <Container className='W_T_cont'>
    <Grid container justify="center" alignItems="center" fixed className="Dec6_Services_GPC_Grid">
    <Grid item lg={4} sm={4} md={4} xs={12} className="Dec6_Services_GPC_Grid2">
            <Container className="Dec6_Services_GPC_Blog">
                <Paper elevation={14} className="Dec6_Services_GPC_Blog_Paper1">
                    <Box className="Dec6_Services_GPC_Blog_Paper_Box">
                        <h3 className="Dec6_Services_GPC_Blog_Paper_Box_H">
                        PYTHON-DJANGO                        </h3>
                        <p className="Blog_cat_p" >
                        Django is a high-level Python web framework that encourages rapid development and clean, pragmatic design. Built by... </p>
                        <i onClick={()=>window.location.pathname='/Insights/PYTHON-DJANGO'} className="fas fa-chevron-circle-right Dec6_Services_WCU_Cont_Icon_Blog1"></i>

                        {/* <div className="Dec6_Services_GPC_Blog_Content">
                            <h3 className="Dec6_Services_GPC_Blog_Paper_Box_SubH">
                                {Dec6_Services_GPC_Blog_List_Digi_Text[0]}
                            </h3> 
                            <h3 className="Dec6_Services_GPC_Blog_Paper_Box_SubH">
                                {Dec6_Services_GPC_Blog_List_Digi_Text[1]}
                            </h3>
                            <h3 className="Dec6_Services_GPC_Blog_Paper_Box_SubH">
                                {Dec6_Services_GPC_Blog_List_Digi_Text[2]}
                            </h3>        
                        </div> */}
                    </Box>
                </Paper>
            </Container>
        </Grid>

        <Grid item lg={4} sm={4} md={4} xs={12} className="Dec6_Services_GPC_Grid2">
            <Container className="Dec6_Services_GPC_Blog">
                <Paper elevation={14} className="Dec6_Services_GPC_Blog_Paper2">
                    <Box className="Dec6_Services_GPC_Blog_Paper_Box">
                        <h3 className="Dec6_Services_GPC_Blog_Paper_Box_H">
                        REACT/REACT-JS                       </h3>
                        <p className="Blog_cat_p" >

                        React.js/React is an open-source frontend framework that is based on JavaScript, developed by Facebook, and best...                      </p>
                        <i onClick={()=>window.location.pathname='/Insights/REACT/REACT-JS'} className="fas fa-chevron-circle-right Dec6_Services_WCU_Cont_Icon_Blog1"></i>

                        {/* <div className="Dec6_Services_GPC_Blog_Content">
                            <h3 className="Dec6_Services_GPC_Blog_Paper_Box_SubH">
                                {Dec6_Services_GPC_Blog_List_Digi_Text[0]}
                            </h3> 
                            <h3 className="Dec6_Services_GPC_Blog_Paper_Box_SubH">
                                {Dec6_Services_GPC_Blog_List_Digi_Text[1]}
                            </h3>
                            <h3 className="Dec6_Services_GPC_Blog_Paper_Box_SubH">
                                {Dec6_Services_GPC_Blog_List_Digi_Text[2]}
                            </h3>        
                        </div> */}
                    </Box>
                </Paper>
            </Container>
        </Grid>

        <Grid item lg={4} sm={4} md={4} xs={12} className="Dec6_Services_GPC_Grid2">
            <Container className="Dec6_Services_GPC_Blog">
                <Paper elevation={14} className="Dec6_Services_GPC_Blog_Paper3">
                    <Box className="Dec6_Services_GPC_Blog_Paper_Box">
                        <h3 className="Dec6_Services_GPC_Blog_Paper_Box_H">
                        ARTIFICIAL INTELLIGENCE                       </h3>
                        <p className="Blog_cat_p" >
                        Artificial intelligence (AI) is a wide-ranging branch of computer science concerned with building smart machines capable of ...                        </p>
                        <i  onClick={()=>window.location.pathname='/Insights/ARTIFICIAL-INTELLIGENCE'} className="fas fa-chevron-circle-right Dec6_Services_WCU_Cont_Icon_Blog1"></i>

                        {/* <div className="Dec6_Services_GPC_Blog_Content">
                            <h3 className="Dec6_Services_GPC_Blog_Paper_Box_SubH">
                                {Dec6_Services_GPC_Blog_List_Digi_Text[0]}
                            </h3> 
                            <h3 className="Dec6_Services_GPC_Blog_Paper_Box_SubH">
                                {Dec6_Services_GPC_Blog_List_Digi_Text[1]}
                            </h3>
                            <h3 className="Dec6_Services_GPC_Blog_Paper_Box_SubH">
                                {Dec6_Services_GPC_Blog_List_Digi_Text[2]}
                            </h3>        
                        </div> */}
                    </Box>
                </Paper>
            </Container>
        </Grid>

        {/* <Grid item lg={4} sm={4} md={4} xs={12} className="Dec6_Services_GPC_Grid2">
            <Container className="Dec6_Services_GPC_Blog">
                <Paper elevation={14} className="Dec6_Services_GPC_Blog_Paper4">
                    <Box className="Dec6_Services_GPC_Blog_Paper_Box">
                        <h3 className="Dec6_Services_GPC_Blog_Paper_Box_H">
                        DRUG ADDICTION                        </h3>
                        <p className="Blog_cat_p" >
                        AATAM AVLOKAN is strongly committed to serving the community. We plays an active role at various levels active role at vari...
                        </p>
                        <i onClick={()=>window.location.pathname='/Blogs/Blog4'} className="fas fa-chevron-circle-right Dec6_Services_WCU_Cont_Icon_Blog1"></i>

                       
                    </Box>
                </Paper>
            </Container>
        </Grid> */}

        {/* <Grid item lg={4} sm={4} md={4} xs={12} className="Dec6_Services_GPC_Grid2">
            <Container className="Dec6_Services_GPC_Blog">
                <Paper elevation={14} className="Dec6_Services_GPC_Blog_Paper5">
                    <Box className="Dec6_Services_GPC_Blog_Paper_Box">
                        <h3 className="Dec6_Services_GPC_Blog_Paper_Box_H">
                        DRUG ADDICTION                        </h3>
                        <p className="Blog_cat_p" >
                        AATAM AVLOKAN is strongly committed to serving the community. We plays an active role at various levels active role at vari...
                        </p>
                        <i onClick={()=>window.location.pathname='/Blogs/Blog5'} className="fas fa-chevron-circle-right Dec6_Services_WCU_Cont_Icon_Blog1"></i>

                    </Box>
                </Paper>
            </Container>
        </Grid> */}
        {/* <Grid item lg={4} sm={4} md={4} xs={12} className="Dec6_Services_GPC_Grid2">
            <Container className="Dec6_Services_GPC_Blog">
                <Paper elevation={14} className="Dec6_Services_GPC_Blog_Paper6">
                    <Box className="Dec6_Services_GPC_Blog_Paper_Box">
                        <h3 className="Dec6_Services_GPC_Blog_Paper_Box_H">
                        DRUG ADDICTION                        </h3>
                        <p className="Blog_cat_p" >
                        AATAM AVLOKAN is strongly committed to serving the community. We plays an active role at various levels active role at vari...
                        </p>
                        <i onClick={()=>window.location.pathname='/Blogs/Blog6'} className="fas fa-chevron-circle-right Dec6_Services_WCU_Cont_Icon_Blog1"></i>

                    </Box>
                </Paper>
            </Container>
        </Grid> */}

        {/* <Grid item lg={4} sm={6} md={4} xs={12} className="Dec6_Services_GPC_Grid5">
            <Container className="Dec6_Services_GPC_Blog">
                <Paper elevation={14} className="Dec6_Services_GPC_Blog_Paper">
                    <Box className="Dec6_Services_GPC_Blog_Paper_Box">
                        <h3 className="Dec6_Services_GPC_Blog_Paper_Box_H">
                            {Dec6_Services_GPC_Blog_Typg_H_Text[4]}
                        </h3>
                        <div className="Dec6_Services_GPC_Blog_Content">
                            <h3 className="Dec6_Services_GPC_Blog_Paper_Box_SubH">
                                {Dec6_Services_GPC_Blog_List_SoftEng_Text[0]}
                            </h3> 
                            <h3 className="Dec6_Services_GPC_Blog_Paper_Box_SubH">
                                {Dec6_Services_GPC_Blog_List_SoftEng_Text[1]}
                            </h3>
                            <h3 className="Dec6_Services_GPC_Blog_Paper_Box_SubH">
                                {Dec6_Services_GPC_Blog_List_SoftEng_Text[2]}
                            </h3>        
                        </div>
                    </Box>
                </Paper>
            </Container>
        </Grid> */}

        {/* <Grid item lg={4} sm={6} md={4} xs={12} className="Dec6_Services_GPC_Grid6">
            <Container className="Dec6_Services_GPC_Blog">
                <Paper elevation={14} className="Dec6_Services_GPC_Blog_Paper">
                    <Box className="Dec6_Services_GPC_Blog_Paper_Box">
                        <h3 className="Dec6_Services_GPC_Blog_Paper_Box_H">
                            {Dec6_Services_GPC_Blog_Typg_H_Text[5]}
                        </h3>
                        <div className="Dec6_Services_GPC_Blog_Content">
                            <h3 className="Dec6_Services_GPC_Blog_Paper_Box_SubH">
                                {Dec6_Services_GPC_Blog_List_Ins_Text[0]}
                            </h3> 
                            <h3 className="Dec6_Services_GPC_Blog_Paper_Box_SubH">
                                {Dec6_Services_GPC_Blog_List_Ins_Text[1]}
                            </h3>
                            <h3 className="Dec6_Services_GPC_Blog_Paper_Box_SubH">
                                {Dec6_Services_GPC_Blog_List_Ins_Text[2]}
                            </h3>        
                        </div>
                    </Box>
                </Paper>
            </Container>
        </Grid> */}
    </Grid>
    </Container>
  );
};

export default Blog_cat;
