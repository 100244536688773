/* -------- Purpose Block (What we Do) Text --------- */
const Dec6_AboutUs_Purpose_Block_Cont_AH_Text = 'About Dec6';

const Dec6_AboutUs_Purpose_Block_Cont_MH = 'What we do';

const Dec6_AboutUs_Purpose_Block_Cont_P = 'We help you see the world differently, discover opportunities you may never have imagined and unlock the potential to achieve desired results. Together, we help you explore endless possibilities to make this world better.';

export {Dec6_AboutUs_Purpose_Block_Cont_AH_Text,Dec6_AboutUs_Purpose_Block_Cont_MH,Dec6_AboutUs_Purpose_Block_Cont_P};

/* -------- Vision Block (What we Believe) Text --------- */
const Dec6_AboutUs_Vision_Block_Cont_MH = 'What we believe';

const Dec6_AboutUs_Vision_Block_Cont_P = 'We believe that the bold steps with the right intent & vision define the future. That the ambition to lead requires the courage to begin & adapt. And that doing the right thing is rarely easy, but always worth it.';

export {Dec6_AboutUs_Vision_Block_Cont_MH,Dec6_AboutUs_Vision_Block_Cont_P};

/* -------- Mission Block Text --------- */
const Dec6_AboutUs_Mission_Block_Cont_MH = 'Our Mission';

const Dec6_AboutUs_Mission_Block_Cont_P = 'To explore, innovate & create groundbreaking sustainable digital solutions for modern day challenges. Build a creative, inclusive & diverse organisation to help our clients create high levels of value that together we set new standards of excellence in our respective industries and make a positive impact on communities around us.';

export {Dec6_AboutUs_Mission_Block_Cont_MH,Dec6_AboutUs_Mission_Block_Cont_P};


/* --------- Team Block Text ----------- */
const Dec6_AboutUs_Team_Block_H_Text = 'Our People';

const Dec6_AboutUs_Team_Block1_Img_Name_Text = ['Kashish','Nilesh','Karen'];

const Dec6_AboutUs_Teams_Block2_Imgs_Name_Text = ['Ankur','Rohan','Aadya'];

const Dec6_AboutUs_Team_Block1_Img_Name_Desig_Text = ['Technology Consultant','Software Developer','Business Consultant'];

const Dec6_AboutUs_Team_Block2_Imgs_Name_Desig_Text = ['Design Consultant','Senior Software Developer','Senior Architect'];

const Dec6_AboutUs_Team_Block1_Img_Desc = ['Kashish likes to build new things, her passion for technology is reflected in every task she undertakes. She is calm, focussed & positive in all situations.',
'Nilesh brings in wealth of expertise in buil- ding new age applications on AI, Block- chain, Django, etc. He is a brilliant problem solver, technologist & a great team player.',
'Karen leads our business development practice, she is well connected & her experience adds in immense value in building greater relations with our clients.'];
/*
const Dec6_AboutUs_Team_Block_Img_Desc = ['Ankur is passionate about content writing, he developed his content writing skills as part of his stint in previous organisation. He brings in extensive experience from product based IT Industry.',
'Sarah is senior web developer, she takes care of core web applications & usually spends time to create part time apps for various charity organisations as well.',
'Aadya is Senior Enterprise Architect, she brings deep experience in system design architecture & system integration. She has overall 18 years of expertise in building, designing enterprise applications.'];
*/

const Dec6_AboutUs_Team_Block2_Imgs_Desc = ['Ankur is passionate about enhancing user experience. His vast knowledge from product based IT Industry adds in a great value in delivering human centric designs for modern digital solutions.',
'Rohan is senior software developer, he takes care of core web applications & usually spends time to create part time apps for various charity organisations as well.',
'Aadya is Senior Enterprise Architect, she brings deep experience in system design architecture & system integration. She has overall 18 years of expertise in building, designing enterprise applications.'];

export {Dec6_AboutUs_Team_Block_H_Text,Dec6_AboutUs_Team_Block2_Imgs_Name_Desig_Text,Dec6_AboutUs_Teams_Block2_Imgs_Name_Text,Dec6_AboutUs_Team_Block2_Imgs_Desc, Dec6_AboutUs_Team_Block1_Img_Name_Desig_Text,Dec6_AboutUs_Team_Block1_Img_Name_Text,Dec6_AboutUs_Team_Block1_Img_Desc};


/* ------- AboutUs_SuccessStoryBlock Text -------- */

const Dec6_AboutUs_SuccessStory_Block_H_Text = 'Our Success Stories';

const Dec6_AboutUs_SuccessStory_Block_P_Text =['We are quite impressed with the quality of the service & the engagement with Dec6. Their domain expertise in cyber security has extensively helped us in building our IT solutions more secure & robust.',
'Dec6 consulting practice provides professional IT services which can truly transform your business & help you achieve greater outcomes. Consultants at Dec6 are skilled, deliver excellent professional services.',
'The product is great. Easy to use, customisable & fit for purpose. Product support team is quick to reply and very helpful. Dec6 product team provides excellent service, definitely recommend for future purposes.'];

const Dec6_AboutUs_SuccessStory_Block_Img_H_Name_Text =['Mark','Liam','Gaurav'];

const Dec6_AboutUs_SuccessStory_Block_Img_H_Desig_Text =['Mark & Co','Fit Exp','P Pharma'];

export {Dec6_AboutUs_SuccessStory_Block_H_Text,Dec6_AboutUs_SuccessStory_Block_Img_H_Name_Text,Dec6_AboutUs_SuccessStory_Block_P_Text,Dec6_AboutUs_SuccessStory_Block_Img_H_Desig_Text};