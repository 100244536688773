import Header from '../Header_RedLine/Dec6_HeaderRL';
import Navbar from '../Navbar/Dec6_Navbar';
import ResponsiveNavbar from '../ResponsiveNavbar/Dec6_Navbar';
import PYG from '../Home_PropelYourGrowth_Cont/Dec6_Home_PYG';
import WWA from '../Home_WhoWeAreBlock/Dec6_Home_WWA';
import BoxGrid1 from '../Home_6Boxes_GridLayout/Dec6_Home_6BoxGL';
import BoxGrid from '../Home_DialogBox_6Boxes_GridLayout/Dec6_DB_6BGL';
import HRLine from '../Home_HorizontalLine_Below6BoxG/Dec6_Home_HR';
import BOS from '../Home_BundleOfServicesBlock/Dec6_Home_BOS';
import AboveFooter from '../AboveFooter/Dec6_AboveFooter';
import Footer from '../Footer/Dec6_Footer';
import Scroll_To_Top from '../Scroll_To_Top/Dec6_STTop';
import {Helmet} from "react-helmet";
import './Dec6_HomePage.css'
function Dec6_HomePage() {
  return (
      <>
      <div className='home_background'>
          <Helmet>
            <title>Home | Dec6 Consulting Pvt Ltd</title>
            <meta name="description" content="Dec6 Consulting Private Limited" />
            <link rel="canonical" href="https://www.dec6.ca/" />
          </Helmet>
          <Scroll_To_Top/>
          <Header/>  
          <ResponsiveNavbar/>   
          <PYG/>  
          <WWA/>
          <BoxGrid/>
          <BOS/>
          <AboveFooter/>
          <Footer/>   
      </div> 
      </>
  );
}

export default Dec6_HomePage;