import React from 'react';
import './Blog_below_img.css';
import "../MainCSS.css";


function Blog_below_img() {
    return(
        <div className="Dec6_Services_WCU_Cont_Blog">
            {/* <h3 className="Dec6_Services_WCU_Cont_H_Blog">
            IMPRESSIONS OF AATAM AVLOKAN CENTER
            </h3>     */}
            <p className="Dec6_Services_WCU_Cont_P_Blog">
            A collection of stories about our people, our capabilities, our research, and the ever-changing face of our firm. 
            Digital technologies are accelerators of innovation. They can improve efficiency, power new products and services, enable new business models, and blur the boundaries between industries.                                 
                               </p>      
                                       <i  className="fas fa-chevron-down Dec6_Services_WCU_Cont_Icon_Blog"></i>
        </div>
    );
}

export default Blog_below_img;