import React from 'react';
import './Dec6_Services_WCU_CSS.css';
import {Dec6_Services_WCU_Cont_H_Text,Dec6_Services_WCU_Cont_P_Text} from '../TextFiles/Dec6_Services_TextFile';

function Dec6_Services_WCU() {
    return(
        <div className="Dec6_Services_WCU_Cont">
            <h3 className="Dec6_Services_WCU_Cont_H">
                {Dec6_Services_WCU_Cont_H_Text}
            </h3>
            <p className="Dec6_Services_WCU_Cont_P">
                {Dec6_Services_WCU_Cont_P_Text}
            </p>
            <i className="fas fa-chevron-down Dec6_Services_WCU_Cont_Icon"></i>
        </div>
    );
}

export default Dec6_Services_WCU;