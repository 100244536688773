import * as React from 'react';
import Box from '@material-ui/core/Box';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ArrowForwardIcon from '@material-ui/icons/ArrowBack';
import './dashboard.css'
export default function TemporaryDrawer(props) {
  // const [state, setState] = React.useState({
  //   top: false,
  //   left: props.open,
  //   bottom: false,
  //   right: false,
  // });
  const [open,setOpen]=React.useState(props.open)


  // const toggleDrawer = (anchor, open) => (event) => {
  //   if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
  //     return;
  //   }

  //   setState({ ...state, [anchor]: open });
  // };
  const Open=()=>{
    setOpen(!open)
  }
const Toggle=()=>{
  props.Toggle()
}
  const list = (anchor) => (
    <Box className='box_dash'
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={Open}
      onKeyDown={Open}
    >
      <List>
        <ListItem onClick={()=>{Toggle()}}>
        <ListItemIcon>
      <ArrowForwardIcon style={{color:'#cc0000'}}  />
      </ListItemIcon>
      </ListItem>

        {['Home', 'About Us','Industries','Our Services'].map((text, index) => (
          <Accordion  onClick={()=>window.location.pathname=`${text=='Home'?'/':text=='About Us'?'AboutUs':text=='Our Services'?'OurServices':text}`} button key={text}>
           <AccordionSummary aria-controls="panel1a-content"
          id="panel1a-header">
            <Typography >{text}</Typography> 
            </AccordionSummary>
          </Accordion>
        ))}
    
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography onClick={()=>window.location.pathname='/Insights'} >Insights</Typography>
        </AccordionSummary>
        <AccordionDetails style={{display:'grid'}}>
          <Typography onClick={()=>window.location.pathname="/Insights/PYTHON-DJANGO"} className='typo'>
          PYTHON-DJANGO 
          </Typography>
          <Typography onClick={()=>window.location.pathname="/Insights/REACT/REACT-JS"} className='typo'>
          REACT/REACT-JS
          </Typography>
          <Typography onClick={()=>window.location.pathname="/Insights/ARTIFICIAL-INTELLIGENCE"}  className='typo'>
          ARTIFICIAL INTELLIGENCE 
          </Typography>
         
       
        </AccordionDetails>
      </Accordion>
  
      {['Join Us','Contact Us'].map((text, index) => (
           <Accordion  onClick={()=>window.location.pathname=`${text==='Contact Us'?'ContactUs':'JoinUs'}`}  key={text}>
           <AccordionSummary aria-controls="panel1a-content"
          id="panel1a-header">
            <Typography >{text}</Typography> 
            </AccordionSummary>
          </Accordion>
        ))}
      </List>

    </Box>
  );

  return (
    // <div>
    <>
      {['left'].map((anchor) => (
        <React.Fragment key={anchor}>
          {/* <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button> */}
          <Drawer
            anchor={'left'}
            open={props.open}
            onClose={Open}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
      </>
    // </div>
  );
}