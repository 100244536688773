import './Dec6_Navbar_CSS.css';
import React,{useState} from 'react';
import Dec6_Logo from '../Images/Dec6Logo.svg';
import {NavLink} from "react-router-dom";
import {Dec6_Home_PYG_Container_H_Text} from '../TextFiles/Dec6_Home_TextFiles';

 function Dec6_Navbar() {

    const [D6_Navbar_Items,setNavbar] = useState(false);

    const changeBackground = () => {
        if(window.scrollY >= 20)
        {
            setNavbar(true);
        }
        else {
            setNavbar(false);
        }
    };

  /*  const changeBackground = () => {
        console.log(window.screenY);
    };*/

    window.addEventListener('scroll',changeBackground);

        return(
        <> 
        
           {/*     <nav className="D6_Navbar_Items D6_Navbar_Items_BGColor">  */}
                <nav className={D6_Navbar_Items ? 'D6_Navbar_Items active' : 'D6_Navbar_Items'}> 

                    <div className="Dec6_HeaderRL_Container1">

                    </div>

                    <div>
                        <img src={Dec6_Logo} className="D6_Navbar_Items_Image" alt="Dec6 Consulting Private Limited" />
                    </div>
                    <ul className="D6_Navbar_Items_List">
                        <li>
                            <NavLink className="D6_Navbar_Items_List_Link_ContactUs" exact to="/ContactUs">Contact Us</NavLink>
                        </li>
                        <li>
                            <NavLink className="D6_Navbar_Items_List_Link_JoinUs"exact to="/JoinUs">Join Us</NavLink>
                        </li>
                        <li>
                            <NavLink className="D6_Navbar_Items_List_Link_Blog" exact to="/Blog">Insights</NavLink>
                        </li>
                        <li>
                            <NavLink className="D6_Navbar_Items_List_Link_OurServices" exact to="/OurServices">Our Services</NavLink>
                        </li>
                        <li>
                            <NavLink className="D6_Navbar_Items_List_Link_Industries" exact to="/Industries">Industries</NavLink>
                        </li>
                        <li>
                            <NavLink className="D6_Navbar_Items_List_Link_AboutUs"  exact to="/AboutUs">About Us</NavLink>
                        </li>
                        <li>
                            <NavLink exact  className="D6_Navbar_Items_List_Link_Home" exact to="/">Home</NavLink>
                        </li>
                    </ul>   
                </nav>
        </>
    )
}
export default Dec6_Navbar;