/* -------- Navbar Menu List --------- */
const Dec6_Navbar_Items1_List2_Text= ['Home','About Us','Industries','Our Services','Insights','Join Us','Contact Us'];
export {Dec6_Navbar_Items1_List2_Text};

/*   -------  Propel Your Groth Text -------    */
const Dec6_Home_PYG_Container_H_Text = ['PROPEL YOUR GROWTH','Bespoke IT Consulting, Products & Services'];

export {Dec6_Home_PYG_Container_H_Text};


/*    ------ BundleOfServicesBlock Text -------     */
const Dec6_BOS_Items_H_Text = "Bundle of services";

const Dec6_BOS_Items_P_Text = "Dec6 brings it’s personalised IT services bundle for it’s customers. We offer bespoke solutions to B2E, B2C & B2G segments.";

export {Dec6_BOS_Items_H_Text, Dec6_BOS_Items_P_Text};

/*    --------  6Boxes_GridLayout Text ---------     */
const Dec6_Home_6BoxGL_MainBox_H_Text = [ 'E-commerce','Healthcare','Renewable Energy','Insurance','Aerospace & Defense','Aviation/Airport Management'];

const Dec6_Home_6BoxGL_MainBox_P_Text = [
    'Demand for frictionless experiences is extending deeper and deeper into e-commerce from finding the right products, to the payments process, and speed and ease',

    'Healthcare companies have the opportunity to create consumer experiences to keep the individual at the forefront through personalized technologies and programs.',

    'Global community is constantly looking for affordable and sustainable energy resources, companies know that powering our world means thinking beyond industry',

    'At Dec6, we work closely with our clients to embrace the power of disruptive technologies, allowing them to drive innovation and sustainable growth for their',

    'More and more aerospace companies are embracing digital technologies like AI, analytics and automation. Forecasts suggests immense potential for aerospace',
    
    'Intense competition, volatile fuel prices, and changing customer preferences and loyalties are just a few of the factors that make aviation services an intensely'
];

const Dec6_Home_6BoxGL_ReadMore_P_Text = [
    'Demand for frictionless experiences is extending deeper and deeper into e-commerce from finding the right products, to the payments process, and speed and ease of delivery. New technologies like digital voice assistants and blockchain are opening new opportunities for commerce every day. It’s time to evolve the e-commerce experience and expand what users are purchasing on your platform with seamless, personalized and convenient new products and services.',

    'Healthcare companies have the opportunity to create consumer experiences to keep the individual at the forefront through personalized technologies and programs. Our committed professionals operate at the intersection of business and technology to combine real-world experience, clinical and business insights and new, enabling intelligent technologies to deliver the power of Insight Driven Health in a demanding new digital world.',

    'Global community is constantly looking for affordable and sustainable energy resources, companies know that powering our world means thinking beyond industry definitions to push performance boundaries, redefine markets and pursue innovation. Newer technologies have opened doors to new dimensions of energy creation & reducing carbon foot-print. Renewable energy can transform process chain & operational costs for organisations.',

    'At Dec6, we work closely with our clients to embrace the power of disruptive technologies, allowing them to drive innovation and sustainable growth for their enterprise. We’ll pair your firm with the right technology tools to help you realize your business goals. New technologies can uplift the value chain immensely.',

    'More and more aerospace companies are embracing digital technologies like AI, analytics and automation. Forecasts suggests immense potential for aerospace industry to grow manifold in coming years. Disruptive technologies have opened new ways of transforming the costs & operations.',
    
    'Intense competition, volatile fuel prices, and changing customer preferences and loyalties are just a few of the factors that make aviation services an intensely demanding industry. Dec6 helps aviation and airport management companies take swift, decisive action and make smart decisions about the strategic, operational and digital challenges that will determine their success.'
]

export {Dec6_Home_6BoxGL_MainBox_H_Text, Dec6_Home_6BoxGL_MainBox_P_Text, Dec6_Home_6BoxGL_ReadMore_P_Text};


/*   -------- Above Footer Text --------   */
const Dec6_AboveFooter_H_Text = ['About Us','Our Services','Contact Us'];

const Dec6_AboveFooter_P_Text = ['Dec6 is an IT Consulting firm providing services across various Industries, we complement our tailored, integrated expertise with a vibrant ecosystem of digital innovators to deliver better, faster and more enduring outcomes.'];

const Dec6_AboveFooter_List2_Text = ['IT Consulting','Web/Mobile Development','Cyber Security','Digital Transformation','Advanced Analytics'];

const Dec6_AboveFooter_List3_Text = ['Email','Call','Chat Online'];

export {Dec6_AboveFooter_H_Text,Dec6_AboveFooter_P_Text,Dec6_AboveFooter_List2_Text,Dec6_AboveFooter_List3_Text};


/*   ----------  Who We Are Block Text ---------  */
const Dec6_WWA_Items_P_Text = ['Who we are?','We are a team of digital innovators, thinking & delivering breakthroughs for modern day challenges.'];

export {Dec6_WWA_Items_P_Text};


