import React,{useEffect} from 'react';
import './Dec6_ContactUs_WTCU_CSS.css';
import {Dec6_ContactUs_WTCU_Block_H1_Text,Dec6_ContactUs_WTCU_Block_H2_Text} from '../TextFiles/Dec6_ContactUs_TextFile';
import Aos from 'aos';
import 'aos/dist/aos.css'; 

function Dec6_ContactUs_WTCU() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []); 

    return(
        <div data-aos="fade-down"
        data-aos-once = "true" className="Dec6_ContactUs_WTCU_Block">
            <h3 data-aos="fade-left"
                    data-aos-delay="150"
                    data-aos-easing="ease-in-out" 
                    data-aos-once = "true"
                     className="Dec6_ContactUs_WTCU_Block_H1">
                {Dec6_ContactUs_WTCU_Block_H1_Text}
            </h3>
            <h3 data-aos="fade-right"
                    data-aos-delay="250"
                    data-aos-easing="ease-in-out" 
                    data-aos-once = "true"
                    className="Dec6_ContactUs_WTCU_Block_H2">
                {Dec6_ContactUs_WTCU_Block_H2_Text}
            </h3>
        </div>
    );
}

export default Dec6_ContactUs_WTCU;