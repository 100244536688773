import React from 'react'
import Blog_below_img from './Blog_below_img'
import Blog_BgImage from './Blog_BgImage'
import Blog_cat from './Blog_cat'
import './Blog.css'
 export default  function Blog(){

return(
<>
<div className='Blog_BG'>

<Blog_BgImage/>
<Blog_below_img/>
</div>
<Blog_cat/>
</>
     )
 }