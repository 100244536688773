import './Dec6_Services_Navbar_CSS.css';
import React from 'react';
import Dec6_Logo from '../Images/Dec6Logo.svg';
import {NavLink} from "react-router-dom";

 function Dec6_Services_Navbar() {
        return(
        <> 
            <div className="D6_Services_Navbar_Container">
                <nav className="D6_Services_Navbar_Items D6_Industries_Navbar_Items_BGColor">
                    <div>
                        <img src={Dec6_Logo} className="D6_Services_Navbar_Items_Image" alt="Dec6 Consulting Private Limited" />
                    </div>
                    <ul className="D6_Services_Navbar_Items_List">
                        <li>
                            <NavLink className="D6_Services_Navbar_Items_List_Link_ContactUs" exact to="/ContactUs">Contact Us</NavLink>
                        </li>
                        <li>
                            <NavLink className="D6_Services_Navbar_Items_List_Link_JoinUs"exact to="/JoinUs">Join Us</NavLink>
                        </li>
                        <li>
                            <NavLink className="D6_Services_Navbar_Items_List_Link_Blog" exact to="/Blog">Blog</NavLink>
                        </li>
                        <li>
                            <NavLink className="D6_Services_Navbar_Items_List_Link_OurServices" exact to="/OurServices">Our Services</NavLink>
                        </li>
                        <li>
                            <NavLink className="D6_Services_Navbar_Items_List_Link_Industries" exact to="/Industries">Industries</NavLink>
                        </li>
                        <li>
                            <NavLink className="D6_Services_Navbar_Items_List_Link_AboutUs"  exact to="/AboutUs">About Us</NavLink>
                        </li>
                        <li>
                            <NavLink exact  className="D6_Services_Navbar_Items_List_Link_Home" exact to="/">Home</NavLink>
                        </li>
                    </ul>   
                </nav>
            </div>
        </>
    )
}
export default Dec6_Services_Navbar;