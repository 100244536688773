/*
import './App.css';
import {Route, Switch, Router} from "react-router-dom";
import createHistory from 'history/createBrowserHistory';

import HomePage from "./components/MainPages/Dec6_HomePage";
import AboutUsPage from "./components/MainPages/Dec6_AboutUsPage";
import IndustriesPage from "./components/MainPages/Dec6_IndustriesPage";
import ServicesPage from "./components/MainPages/Dec6_ServicesPage";
import InsightsPage from "./components/MainPages/Dec6_BlogPage";
import JoinUsPage from "./components/MainPages/Dec6_JoinUsPage";
import ContactUsPage from "./components/MainPages/Dec6_ContactUsPage";
import PrivacyPolicy from "./components/MainPages/Dec6_PrivacyPolicyPage";
import ScrollNPT from "./components/Scroll_NewPage_Top/Dec6_ScrollNPT";

/*function App() { 

  class App {

  render() {

    const history = createHistory();
    history.listen((location, action) => {
        console.log("inside history listen");
    });  

  return (
      <>
      <ScrollNPT/>
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/AboutUs" component={AboutUsPage} />
          <Route exact path="/Industries" component={IndustriesPage} />
          <Route exact path="/OurServices" component={ServicesPage} />
          <Route exact path="/Insights" component={InsightsPage} />
          <Route exact path="/JoinUs" component={JoinUsPage} />
          <Route exact path="/ContactUs" component={ContactUsPage} />
          <Route exact path="/PrivacyPolicy" component={PrivacyPolicy} />
        </Switch>  
      </>
  );
}

export default App;

*/




import React from 'react'
import {Route, Switch, Router, Redirect} from "react-router-dom";
import HomePage from "./components/MainPages/Dec6_HomePage";
import AboutUsPage from "./components/MainPages/Dec6_AboutUsPage";
import IndustriesPage from "./components/MainPages/Dec6_IndustriesPage";
import ServicesPage from "./components/MainPages/Dec6_ServicesPage";
import InsightsPage from "./components/MainPages/Dec6_BlogPage";
import JoinUsPage from "./components/MainPages/Dec6_JoinUsPage";
import ContactUsPage from "./components/MainPages/Dec6_ContactUsPage";
import PrivacyPolicy from "./components/MainPages/Dec6_PrivacyPolicyPage";
import TAC from "./components/MainPages/Dec6_TermsandConditions";
import ScrollNPT from "./components/Scroll_NewPage_Top/Dec6_ScrollNPT";
import {Helmet} from "react-helmet";
import Blog from './components/Blog/Blog';
import Dec6_Blog from './components/MainPages/Dec6_Blog';
import Blog1 from './components/Blog/Blog1/Blog1';
import Blog2 from './components/Blog/Blog2/Blog2';
import Blog3 from './components/Blog/Blog3/Blog3';



const App = () => {
/*  render() 

    const history = createHistory();
    history.listen((location, action) => {
        console.log("inside history listen");
    });*/

    


  return (
    <>
        <Helmet>
            <title>Dec6 Consulting Pvt Ltd</title>
            <meta name="description" content="A private consulting firm" />
            <link rel="canonical" href="https://www.dec6.ca/" />
        </Helmet>
      <ScrollNPT/>
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/AboutUs" component={AboutUsPage} />
          <Route exact path="/Industries" component={IndustriesPage} />
          <Route exact path="/OurServices" component={ServicesPage} />
          {/* <Route exact path="/Insights" component={InsightsPage} /> */}
          <Route exact path="/JoinUs" component={JoinUsPage} />
          <Route exact path="/ContactUs" component={ContactUsPage} />
          <Route exact path="/PrivacyPolicy" component={PrivacyPolicy} />
          <Route exact path="/Terms&Conditions" component={TAC} />
          <Route exact path="/Insights" component={Dec6_Blog} />
          <Route exact path="/Insights/PYTHON-DJANGO" component={Blog1} /> 
          <Route exact path="/Insights/REACT/REACT-JS" component={Blog2} /> 
          <Route exact path="/Insights/ARTIFICIAL-INTELLIGENCE" component={Blog3} /> 

          <Redirect to="/"/>
        </Switch>  
    </>
  )
}

export default App


