import React from "react";
// import { makeStyles } from "@mui/styles";
import { Grid, Container, Typography } from "@material-ui/core";

import "../../MainCSS.css";
import "./Blog1_cat.css"
const Blog1_cat = () => {
  return (
    <div>
    
      <Container className="D6_ADS_ContBlog1">
        <Grid
          container
          justify="center"
          alignItems="center"
          fixed
          className="D6_ADS_GridBlog1"
        >
          <Grid item sm={6} xs={12} className="D6_ADS_Grid1Blog1">
            <div className="D6_ADS_G1_DivBlog1">
            
     {/* <h3 className="D6_ADS_G1_DivH" style={D6_ADS_G1_DivH}>
                AbFam RadianZ
              </h3> */}
              <div className='D6_SL_WS_Grid2_HDivBlog1'>
               
               <Typography component="h3" className='D6_SL_WS_Grid2_Div_H1Blog1'>PYTHON - DJANGO </Typography>
           </div>
 <Typography           component="p"
 className="D6_ADS_G1_DivPBlog1_B">
Django is a high-level Python web framework that encourages rapid development and clean, pragmatic design. Built by experienced developers, it takes care of much of the hassle of web development, so you can focus on writing your app without needing to reinvent the wheel. It’s free and open source. </Typography>
 <Typography           component="p"
 className="D6_ADS_G1_DivPBlog1_B">
Django's configuration system allows third party code to be plugged into a regular project, provided that it follows the reusable app conventions. More than 2500 packages are available to extend the framework's original behavior, providing solutions to issues the original tool didn't tackle: registration, search, API provision and consumption, CMS, etc.  

              </Typography>

              <Typography           component="p"
 className="D6_ADS_G1_DivPBlog1_B">
Frameworks such as Django or Cherrypy are much too large for migration. Contrary to popular belief, YouTube uses Python for these core features, to control templates, administer videos or even access canonical data for example, it is everywhere within the platform.
  </Typography>
 </div>
          </Grid>

         
          <Grid item xs={12} sm={6} className="D6_ADS_Grid1Blog1">
            {/* <div className="D6_ADS_G1_DivBlog1" >
              <img src={SkinImage} alt="Image" className="D6_ADS_ImgBlog1" />
            </div> */}
          </Grid>

      
      
        </Grid>

      </Container>
    </div>
  );
};

export default Blog1_cat;
