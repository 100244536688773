import React from 'react';
import './Dec6_Services_4PCB_CSS.css';
import '../MainCSS.css';
import {Paper,Container,Typography,Box,Grid} from "@material-ui/core";
import {Dec6_Services_4PCB_Cont_Typ_H_Text,Dec6_Services_4PCB_Cont_Typ_P_Text} from '../TextFiles/Dec6_Services_TextFile';

const Dec6_Services_4PCB_Cont_Typ_H_Style = {
    fontSize : '1rem',
    paddingTop : '20px',
    fontWeight : '700'
}
const Dec6_Services_4PCB_Cont_Typ_P_Style = {
    fontSize : '0.8rem',
    paddingTop : '30px',
    fontWeight: 'lighter',
    paddingLeft : '20px',
    paddingRight : '20px'
}

function Dec6_Services_4PCB() {
    return(
    /*    <div className="Services_PCCB_BG">    */
    <Container>
    <Grid container justify="center" alignItems="center" fixed className="Dec6_Services_4PCB_Grid"   >
     {/*   <div className="Dec6_Services_4PCB_MainBlock">   */}
        <Grid item lg={3} sm={6} md={3} xs={12} className="Dec6_Services_4PCB_Grid1">
            <Container className="Dec6_Services_4PCB_Cont">
                <Paper elevation={14} className="Dec6_Services_4PCB_Cont_Paper">
                    <Box className="Dec6_Services_4PCB_Cont_Paper_Box">
                        <i className="fa fa-handshake-o Dec6_Services_4PCB_Cont_Paper_Box_Icon"></i>
                        <h3 className="Dec6_Services_4PCB_Cont_Typ_H">
                            {Dec6_Services_4PCB_Cont_Typ_H_Text[0]}
                        </h3>
                        <h3 className="Dec6_Services_4PCB_Cont_Typ_P">
                            {Dec6_Services_4PCB_Cont_Typ_P_Text[0]}
                        </h3>
                    </Box>
                </Paper>
            </Container>
        </Grid>

        <Grid item lg={3} sm={6} md={3} xs={12} className="Dec6_Services_4PCB_Grid2"  >
            <Container className="Dec6_Services_4PCB_Cont">
                <Paper elevation={14} className="Dec6_Services_4PCB_Cont_Paper">
                    <Box className="Dec6_Services_4PCB_Cont_Paper_Box">
                        <i className="fas fa-laptop Dec6_Services_4PCB_Cont_Paper_Box_Icon"></i>
                        <h3 className="Dec6_Services_4PCB_Cont_Typ_H">
                            {Dec6_Services_4PCB_Cont_Typ_H_Text[1]}
                        </h3>
                        <h3 className="Dec6_Services_4PCB_Cont_Typ_P">
                            {Dec6_Services_4PCB_Cont_Typ_P_Text[1]}
                        </h3>
                    </Box>
                </Paper>
            </Container>
        </Grid>

        <Grid item lg={3} sm={6} md={3} xs={12} className="Dec6_Services_4PCB_Grid3"  >
            <Container className="Dec6_Services_4PCB_Cont">
                <Paper elevation={14} className="Dec6_Services_4PCB_Cont_Paper">
                    <Box className="Dec6_Services_4PCB_Cont_Paper_Box">
                        <i className="fas fa-user-secret Dec6_Services_4PCB_Cont_Paper_Box_Icon"></i>
                        <h3 className="Dec6_Services_4PCB_Cont_Typ_H">
                            {Dec6_Services_4PCB_Cont_Typ_H_Text[2]}
                        </h3>
                        <h3 className="Dec6_Services_4PCB_Cont_Typ_P">
                            {Dec6_Services_4PCB_Cont_Typ_P_Text[2]}
                        </h3>
                    </Box>
                </Paper>
            </Container>
        </Grid>  

        <Grid item lg={3} sm={6} md={3} xs={12} className="Dec6_Services_4PCB_Grid4"  >
            <Container className="Dec6_Services_4PCB_Cont">
                <Paper elevation={14} className="Dec6_Services_4PCB_Cont_Paper">
                    <Box className="Dec6_Services_4PCB_Cont_Paper_Box">
                        <i className="fas fa-barcode Dec6_Services_4PCB_Cont_Paper_Box_Icon"></i>
                        <h3 className="Dec6_Services_4PCB_Cont_Typ_H">
                            {Dec6_Services_4PCB_Cont_Typ_H_Text[3]}
                        </h3>
                        <h3 className="Dec6_Services_4PCB_Cont_Typ_P">
                            {Dec6_Services_4PCB_Cont_Typ_P_Text[3]}
                        </h3>
                    </Box>
                </Paper>
            </Container>
        </Grid>
    </Grid>

    </Container>
    );
}

export default Dec6_Services_4PCB;