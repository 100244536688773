import React from 'react';
import styled from 'styled-components';
import {NavLink} from "react-router-dom";
import './Dec6_Menu_CSS.css';

const Ul = styled.ul`
    list-style: none;
    display: flex;
    flex-flow: row nowrap;
    padding-top: 25px;
    padding-right: 80px;
    display: none;
    font-family: var(--fontfamily);

 /* div {
    width: 100%;
    background-color: black;
    opacity: 0.5;
    margin-top: 9px;
  }*/
  li {
    padding: 28px 20px;
  }
  li a{
    text-decoration: none;
    color: white;
    font-size: 1.2rem;
    width: 100px;
}
/*  @media (max-width: 768px) {  */
    display: flex;
    flex-flow: column nowrap;
    background-color: var(--whitecolor);
    position: fixed;   
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(-100%)'};
    top: 111.7px;
    left:0;
  /*  left: 40;*/
    height: 423px;
 /*   padding-right: 10px;  */
 /*   width: 150px;  */
   /* padding-top: 6.4rem;*/
    transition: transform 0.3s ease-in-out; 
    li {
    padding-bottom: 2px ;
    padding-left:40px;
    padding-right: 10px;
  }
    li a{
      color: black;
      margin-top: 2.3rem;
      font-size: 1.1rem;
      transition: transform 0.3sec;
    }
    li a:hover {
      color: var(--redcolor);
    }
    .Dec6_Navbar_Items li .active {
    color:Black ;
    border-bottom: 2px solid var(--redcolor);
    padding: 8px 0px;
 }

 @media (max-width: 1200px) {
  top: 86.2px;
}
  
`;

const Dec6_Menu = ({ open }) => {
  return (
    
    <Ul open={open}>
    <div className="Dec6_Navbar_Items">
                <li>
                    <NavLink exact  className="Dec6_Navbar_Items1_List_Link_Home" activeClassName="active" exact to="/">Home</NavLink>
                </li>
                <li>
                            <NavLink className="Dec6_Navbar_Items1_List_Link_AboutUs" activeClassName="active"  exact to="/AboutUs">About Us</NavLink>
                        </li>
                        <li>
                            <NavLink className="Dec6_Navbar_Items1_List_Link_Industries" activeClassName="active" exact to="/Industries">Industries</NavLink>
                        </li>
                        <li>
                            <NavLink className="Dec6_Navbar_Items1_List_Link_OurServices" activeClassName="active" exact to="/OurServices">Our Services</NavLink>
                        </li>
                       <li>
                            <NavLink className="Dec6_Navbar_Items1_List_Link_Blog" activeClassName="active" exact to="/Insights">Insights</NavLink>
                        </li>    
                        <li>
                            <NavLink className="Dec6_Navbar_Items1_List_Link_JoinUs" activeClassName="active" exact to="/JoinUs">Join Us</NavLink>
                        </li>
                        <li>
                            <NavLink className="Dec6_Navbar_Items1_List_Link_ContactUs" activeClassName="active" exact to="/ContactUs">Contact Us</NavLink>
                        </li>   
                        </div>         
    </Ul>
    
  )
}

export default Dec6_Menu
