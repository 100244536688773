import React,{useEffect} from 'react';
import './Dec6_AboutUs_Vision_CSS.css';
import {Dec6_AboutUs_Purpose_Block_Cont_AH_Text,Dec6_AboutUs_Vision_Block_Cont_MH,Dec6_AboutUs_Vision_Block_Cont_P} from '../TextFiles/Dec6_AboutUs_TextFile';
import Aos from 'aos';
import 'aos/dist/aos.css';

function Dec6_AboutUs_Vision() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []); 

    return(
        <div data-aos="fade-up" data-aos-once = "true" className="Dec6_AboutUs_Vision_Block">
            <div data-aos="fade-right"
                    data-aos-delay="90"
                    data-aos-duration="2000"
                    data-aos-once = "true"
                    data-aos-easing="ease-in-out-cubic"
                    className="Dec6_AboutUs_Vision_Block_Cont">
            {/*    <h3 data-aos="fade-right"
                    data-aos-delay="120"
                    data-aos-once = "true"
                    className="Dec6_AboutUs_Vision_Block_Cont_AH">
                    {Dec6_AboutUs_Purpose_Block_Cont_AH_Text}
                </h3>   */}
                <h4 data-aos="fade-left"
                    data-aos-delay="170"
                    data-aos-once = "true"
                    className="Dec6_AboutUs_Vision_Block_Cont_MH">
                    {Dec6_AboutUs_Vision_Block_Cont_MH}
                </h4>
                <p  data-aos="flip-down"
                    data-aos-delay="250"
                    data-aos-duration="4000"
                    data-aos-easing="ease-in-out-cubic" 
                    data-aos-once = "true"
                    className="Dec6_AboutUs_Vision_Block_Cont_P">
                    {Dec6_AboutUs_Vision_Block_Cont_P}
                </p>
            </div>
        </div>
    );
}

export default Dec6_AboutUs_Vision;