import React from 'react'
import HeaderLine from '../Header_RedLine/Dec6_HeaderRL';
import ResponsiveNavbar from '../ResponsiveNavbar/Dec6_Navbar';
import PrivacyPolicy from '../PrivacyPolicy_Page/Dec6_PrivacyPolicy';
import AboveFooter from '../AboveFooter/Dec6_AboveFooter';
import Footer from '../Footer/Dec6_Footer';
import Scroll_To_Top from '../Scroll_To_Top/Dec6_STTop';
import {Helmet} from "react-helmet";

const Dec6_PrivacyPolicyPage = () => {
    return (
        <div>
            <Helmet>
                <title>Privacy Policy | Dec6 Consulting Pvt Ltd</title>
                <meta name="description" content="Dec6 Consulting Private Limited" />
                <link rel="canonical" href="https://www.dec6.ca/PrivacyPolicy" />
            </Helmet>
            <Scroll_To_Top/>
            <HeaderLine/>
            <ResponsiveNavbar/>
            <PrivacyPolicy/>
            <AboveFooter/>
            <Footer/> 
        </div>
    )
}

export default Dec6_PrivacyPolicyPage
