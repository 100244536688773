import React from'react';
import './Dec6_Services_NBBPC_CSS.css';
import {Dec6_Services_NBBPC_Block_H_Text,Dec6_Services_NBBPC_Block_P_Text} from '../TextFiles/Dec6_Services_TextFile';

const Dec6_Services_NBBPC_Block_P_Bold = {
    fontWeight: 'bold'
}

function Dec6_Services_NBBPC() {
    return(
        <div className="Dec6_Services_NBBPC_Block">
            <h3 className="Dec6_Services_NBBPC_Block_H1">
                {Dec6_Services_NBBPC_Block_H_Text[0] }
            </h3>
            <h3 className="Dec6_Services_NBBPC_Block_H2">
                {Dec6_Services_NBBPC_Block_H_Text[1]}
            </h3>
            <p className="Dec6_Services_NBBPC_Block_P">
                {Dec6_Services_NBBPC_Block_P_Text[0] + Dec6_Services_NBBPC_Block_P_Text[1] + Dec6_Services_NBBPC_Block_P_Text[2]}
            </p>
            <i className="fas fa-chevron-down Dec6_Services_NBBPC_Block_Icon"></i>
        </div>
    );
}

export default Dec6_Services_NBBPC;