import React from 'react';
import './Dec6_Industries_B2ESec_CSS.css';
import '../MainCSS.css';
import {Paper,Container,Typography,Button,Box,Grid} from "@material-ui/core";
import {Dec6_Industries_B2B_Cont1_Typo_Text,Dec6_Industries_B2B_Cont2_Typo_Text,Dec6_Industries_B2B_Cont3_Typo_Text} from '../TextFiles/Dec6_Industries_TextFile';

const Dec6_Industries_B2B_Cont_Typ3_Style1 = {
    fontSize : '0.95rem',
    paddingTop : '20px',
    paddingBottom : '5px'
}
const Dec6_Industries_B2B_Cont_Typ3_Style = {
    fontSize : '0.95rem',
    paddingBottom : '5px'
}
const Dec6_Industries_B2B_Cont_Typ2_Style = {
    fontSize : '1rem',
    paddingTop : '5px',
    fontWeight: 'lighter'
}
const Dec6_Industries_B2B_Cont_Button_Style = {
    fontSize : '1rem',
    marginTop : '20px',
    color : 'var(--whitecolor)',
    backgroundColor : 'var(--redcolor)',
    paddingBottom : '5px'
}

const Grid_Style = {
    justifyItems: 'center',
    alignItems: 'center',
    direction: 'row'
}

function Dec6_Industries_B2ESec() {
    return(
    <Container>
        <Grid container justify="center" alignItems="center" fixed className="Dec6_Industries_B2B_Grid"   >
               
   {/*     <div className="Dec6_Industries_B2B_Cont_MainBlock">    */}
        <Grid item lg={4} sm={12} md={4} xs={12} className="Dec6_Industries_B2B_Grid1"  >
            <Container className="Dec6_Industries_B2B_Cont Dec6_Industries_B2B_Cont1">
                <Paper elevation={14} className="Dec6_Industries_B2B_Cont_Paper">
                    <Box className="Dec6_Industries_B2B_Cont_Paper_Box">
                        <Typography variant="h5" className="Dec6_Industries_B2B_Cont_Typ1">
                            {Dec6_Industries_B2B_Cont1_Typo_Text[0]}  
                        </Typography>
                        <Typography style={Dec6_Industries_B2B_Cont_Typ2_Style} className="Dec6_Industries_B2B_Cont_Typ2">
                            {Dec6_Industries_B2B_Cont1_Typo_Text[1]}  
                        </Typography>
                        <Typography style={Dec6_Industries_B2B_Cont_Typ3_Style1} className="Dec6_Industries_B2B_Cont_Typ3">
                           {Dec6_Industries_B2B_Cont1_Typo_Text[2]}  
                        </Typography>
                        <Typography style={Dec6_Industries_B2B_Cont_Typ3_Style} className="Dec6_Industries_B2B_Cont_Typ3">
                           {Dec6_Industries_B2B_Cont1_Typo_Text[3]}
                        </Typography>
                        <Typography style={Dec6_Industries_B2B_Cont_Typ3_Style} className="Dec6_Industries_B2B_Cont_Typ3">
                            {Dec6_Industries_B2B_Cont1_Typo_Text[4]} 
                        </Typography>
                        <Typography style={Dec6_Industries_B2B_Cont_Typ3_Style} className="Dec6_Industries_B2B_Cont_Typ3">
                            {Dec6_Industries_B2B_Cont1_Typo_Text[5]}  
                        </Typography>
                        <Typography style={Dec6_Industries_B2B_Cont_Typ3_Style} className="Dec6_Industries_B2B_Cont_Typ3">
                            {Dec6_Industries_B2B_Cont1_Typo_Text[6]} 
                        </Typography>
                        <Typography style={Dec6_Industries_B2B_Cont_Typ3_Style} className="Dec6_Industries_B2B_Cont_Typ3">
                            {Dec6_Industries_B2B_Cont1_Typo_Text[7]} 
                        </Typography>
                        <Button style={Dec6_Industries_B2B_Cont_Button_Style} className="Dec6_Industries_B2B_Cont_Button" variant="contained">Read More</Button>
                    </Box>
                </Paper>
            </Container>
        </Grid>
        <Grid item lg={4} sm={12} md={4} xs={12} className="Dec6_Industries_B2B_Grid2">
            <Container className="Dec6_Industries_B2B_Cont">
                <Paper elevation={14} className="Dec6_Industries_B2B_Cont_Paper">
                    <Box className="Dec6_Industries_B2B_Cont_Paper_Box">
                    <Typography variant="h5" className="Dec6_Industries_B2B_Cont_Typ1">
                            {Dec6_Industries_B2B_Cont2_Typo_Text[0]}
                        </Typography>
                        <Typography style={Dec6_Industries_B2B_Cont_Typ2_Style}  className="Dec6_Industries_B2B_Cont_Typ2">
                            {Dec6_Industries_B2B_Cont2_Typo_Text[1]}
                        </Typography>
                        <Typography style={Dec6_Industries_B2B_Cont_Typ3_Style1} className="Dec6_Industries_B2B_Cont_Typ3">
                            {Dec6_Industries_B2B_Cont2_Typo_Text[2]}
                        </Typography>
                        <Typography style={Dec6_Industries_B2B_Cont_Typ3_Style} className="Dec6_Industries_B2B_Cont_Typ3">
                            {Dec6_Industries_B2B_Cont2_Typo_Text[3]}
                        </Typography>
                        <Typography style={Dec6_Industries_B2B_Cont_Typ3_Style} className="Dec6_Industries_B2B_Cont_Typ3">
                            {Dec6_Industries_B2B_Cont2_Typo_Text[4]}
                        </Typography>
                        <Typography style={Dec6_Industries_B2B_Cont_Typ3_Style} className="Dec6_Industries_B2B_Cont_Typ3">
                            {Dec6_Industries_B2B_Cont2_Typo_Text[5]}
                        </Typography>
                        <Typography style={Dec6_Industries_B2B_Cont_Typ3_Style} className="Dec6_Industries_B2B_Cont_Typ3">
                            {Dec6_Industries_B2B_Cont2_Typo_Text[6]}
                        </Typography>
                        <Typography style={Dec6_Industries_B2B_Cont_Typ3_Style} className="Dec6_Industries_B2B_Cont_Typ3">
                            {Dec6_Industries_B2B_Cont2_Typo_Text[7]}
                        </Typography>
                        <Button style={Dec6_Industries_B2B_Cont_Button_Style}  variant="contained">Read More</Button>
                    </Box>
                </Paper>
            </Container>
            </Grid>

            <Grid item lg={4} sm={12} md={4} xs={12} className="Dec6_Industries_B2B_Grid3" >
            <Container className="Dec6_Industries_B2B_Cont">
                <Paper elevation={14} className="Dec6_Industries_B2B_Cont_Paper">
                    <Box className="Dec6_Industries_B2B_Cont_Paper_Box">
                        <Typography variant="h5" className="Dec6_Industries_B2B_Cont_Typ1">
                            {Dec6_Industries_B2B_Cont3_Typo_Text[0]}
                        </Typography>
                        <Typography style={Dec6_Industries_B2B_Cont_Typ2_Style}  className="Dec6_Industries_B2B_Cont_Typ2">
                            {Dec6_Industries_B2B_Cont3_Typo_Text[1]}
                        </Typography>
                        <Typography style={Dec6_Industries_B2B_Cont_Typ3_Style1} className="Dec6_Industries_B2B_Cont_Typ3">
                            {Dec6_Industries_B2B_Cont3_Typo_Text[2]}
                        </Typography>
                        <Typography style={Dec6_Industries_B2B_Cont_Typ3_Style} className="Dec6_Industries_B2B_Cont_Typ3">
                            {Dec6_Industries_B2B_Cont3_Typo_Text[3]}
                        </Typography>
                        <Typography style={Dec6_Industries_B2B_Cont_Typ3_Style} className="Dec6_Industries_B2B_Cont_Typ3">
                            {Dec6_Industries_B2B_Cont3_Typo_Text[4]}
                        </Typography>
                        <Typography style={Dec6_Industries_B2B_Cont_Typ3_Style} className="Dec6_Industries_B2B_Cont_Typ3">
                            {Dec6_Industries_B2B_Cont3_Typo_Text[5]}
                        </Typography>
                        <Typography style={Dec6_Industries_B2B_Cont_Typ3_Style} className="Dec6_Industries_B2B_Cont_Typ3">
                            {Dec6_Industries_B2B_Cont3_Typo_Text[6]}
                        </Typography>
                        <Typography style={Dec6_Industries_B2B_Cont_Typ3_Style} className="Dec6_Industries_B2B_Cont_Typ3">
                            {Dec6_Industries_B2B_Cont3_Typo_Text[7]}
                        </Typography>
                        <Button style={Dec6_Industries_B2B_Cont_Button_Style}  variant="contained">Read More</Button>
                    </Box>
                </Paper>
            </Container>
            </Grid>
    {/*    </div>    */}
        </Grid>
        </Container>
    );
}

export default Dec6_Industries_B2ESec;