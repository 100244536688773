import React from 'react';
import './Dec6_Footer_CSS.css';
import {NavLink} from "react-router-dom";

function Dec6_Footer() {
    return(
        <div className="Dec6_Footer_Items_Block">
            <div className="Dec6_Footer_Items">
                <div className="Dec6_Footer_Items_NavLink Dec6_Footer_Items_NavLink1">
                    <NavLink className="Dec6_Footer_Link" exact to="/">
                            HOME
                    </NavLink>
                </div>
                <div className="Dec6_Footer_Items_NavLink Dec6_Footer_Items_NavLink2">
                    <NavLink className="Dec6_Footer_Link" exact to="/PrivacyPolicy">
                            PRIVACY POLICY
                    </NavLink>
                </div>
                <div className="Dec6_Footer_Items_NavLink Dec6_Footer_Items_NavLink3">
                    <NavLink className="Dec6_Footer_Link" exact to="/Terms&Conditions">
                            TERMS    &    CONDITIONS
                    </NavLink>
                </div>
                <div className="Dec6_Footer_Items_P_Div">  
                    <p className="Dec6_Footer_Items_P">
                        &#169;2021 Dec6 Consulting Private Limited
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Dec6_Footer;