import HeaderLine from '../Header_RedLine/Dec6_HeaderRL';
import Services_Navbar from '../Services_Navbar/Dec6_Services_Navbar';
import Navbar from '../Navbar/Dec6_Navbar';
import ResponsiveNavbar from '../ResponsiveNavbar/Dec6_Navbar';
import Services_WCU from '../Services_WhyChooseUs_Block/Dec6_Services_WCU';
import Services_4PBC from '../Services_4PaperCompBlock/Dec6_Services_4PCB';
import Services_NBBPC from '../Services_NextBlockBelow4PC/Dec6_Services_NBBPC';
import Services_GPC from '../Services_8GridPaperComp/Dec6_Services_GPC';
import AboveFooter from '../AboveFooter/Dec6_AboveFooter';
import Footer from '../Footer/Dec6_Footer';
import Scroll_To_Top from '../Scroll_To_Top/Dec6_STTop';
import {Helmet} from "react-helmet";

function Dec6_AboutUsPage() {
  return (
      <>
        <Helmet>
            <title>Our Services | Dec6 Consulting Pvt Ltd</title>
            <meta name="description" content="Dec6 Consulting Private Limited" />
            <link rel="canonical" href="https://www.dec6.ca/OurServices" />
        </Helmet>
        <Scroll_To_Top/>
        <HeaderLine/>
        <ResponsiveNavbar/>
        <Services_WCU/>
        <Services_4PBC/>  
        <Services_NBBPC/>
        <Services_GPC/>
        <AboveFooter/>
        <Footer/>
      </>
  );
}

export default Dec6_AboutUsPage;