import React from 'react';
import './Dec6_AboveFooter_CSS.css';
import {Container,Grid} from "@material-ui/core";
import {Dec6_AboveFooter_H_Text,Dec6_AboveFooter_P_Text,Dec6_AboveFooter_List2_Text,Dec6_AboveFooter_List3_Text} from '../TextFiles/Dec6_Home_TextFiles';

function Dec6_AboveFooter() {
    return(
        <div className="Dec6_AboveFooter_Container"> 

            <div className="Dec6_AboveFooter_Item" id="Dec6_AboveFooter_Item-1">
         {/*       <h3 className="Dec6_AboveFooter_H1">About Us</h3>    */}
                <h3 className="Dec6_AboveFooter_H1">{Dec6_AboveFooter_H_Text[0]}</h3>
                <p className="Dec6_AboveFooter_P">
                    {Dec6_AboveFooter_P_Text[0]}
                </p>
                <div className="Dec6_AboveFooter_SymbolsList1">
                    <a href="https://www.facebook.com/dec6consulting/" target="_blank" className="fab fa-facebook-f fa-5x circle-icon circle-icon-f aicon centericons"></a>
                    <a href="https://twitter.com/Dec6Consulting" target="_blank" className="fab fa-twitter fa-5x circle-icon circle-icon-tw aicon"></a>
                    <a href="https://www.linkedin.com/company/dec6consulting" target="_blank" className="fab fa-linkedin-in fa-5x circle-icon circle-icon-in aicon"></a>
                    <a href="https://www.instagram.com/dec6consulting/?igshid=13uejqzwrqm0d" target="_blank" className="fab fa-instagram fa-5x circle-icon aicon"></a>   
                </div>    
            </div>

            <div className="Dec6_AboveFooter_Item" id="Dec6_AboveFooter_Item-2">
                <h3 className="Dec6_AboveFooter_H2">{Dec6_AboveFooter_H_Text[1]}</h3>
                <ul className="Dec6_AboveFooter_List2">
                    <li>
                        <a href="">{Dec6_AboveFooter_List2_Text[0]}</a>
                    </li>
                    <li>
                        <a href="">{Dec6_AboveFooter_List2_Text[1]}</a>
                    </li>
                    <li>
                        <a href="">{Dec6_AboveFooter_List2_Text[2]}</a>
                    </li>
                    <li>
                        <a href="">{Dec6_AboveFooter_List2_Text[3]}</a>
                    </li>
                    <li>
                        <a href="">{Dec6_AboveFooter_List2_Text[4]}</a>
                    </li>
                </ul>
            </div>

            <div className="Dec6_AboveFooter_Item" id="Dec6_AboveFooter_Item-3">
                <h3 className="Dec6_AboveFooter_H3">{Dec6_AboveFooter_H_Text[2]}</h3>
                <ul className="Dec6_AboveFooter_List3">
                    <li>
                        <i className="fa fa-envelope"></i>
                        <a href="mailto:info@dec6.ca">{Dec6_AboveFooter_List3_Text[0]}</a>
                    </li>
                    <li>
                        <i className="fas fa-phone-alt"></i>
                        <a href="https://www.dec6.ca/ContactUs/" target="_blank">{Dec6_AboveFooter_List3_Text[1]}</a>
                    </li>
                    <li>
                        <i className="fab fa-whatsapp"></i>
                        <a href="https://api.whatsapp.com/message/7DOSVHMKRIZUM1" target="_blank">{Dec6_AboveFooter_List3_Text[2]}</a>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default Dec6_AboveFooter;