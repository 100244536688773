import React from 'react'
import {Grid,Container} from '@material-ui/core';
import Ankur from '../Images/Ankur.jpg';
import Sarah from '../Images/Sarah.jpg';
import Aadya from '../Images/Aadya.jpg';
import Nilesh from '../Images/Nilesh.jpeg';
import Person2 from '../Images/Person2.jpeg';
import Karen from '../Images/Karen.JPG';
import Rohan from '../Images/Rohan.jpg';
import Kashish from '../Images/Kashish.jpg';
import {Dec6_AboutUs_Team_Block_H_Text,Dec6_AboutUs_Team_Block2_Imgs_Name_Desig_Text,Dec6_AboutUs_Teams_Block2_Imgs_Name_Text,Dec6_AboutUs_Team_Block2_Imgs_Desc,Dec6_AboutUs_Team_Block1_Img_Name_Desig_Text,Dec6_AboutUs_Team_Block1_Img_Name_Text,Dec6_AboutUs_Team_Block1_Img_Desc} from '../TextFiles/Dec6_AboutUs_TextFile';
import Aos from 'aos';
import 'aos/dist/aos.css'; 
import './Dec6_TeamGrid_CSS.css';

const Dec6_TeamGrid = () => {
    return (
        <Container className="D6_Team_Cont">
        <Grid container justify="center" alignItems="center" fixed className="D6_Team_Grid">

            <Grid item lg={12} md={12} sm={12} xs={12} className="D6_Team_Grid1">
                    <div className="D6_Team_Grid1_H_Div">
                        <h3 className="D6_Team_Grid1_H">
                            {Dec6_AboutUs_Team_Block_H_Text}
                        </h3>
                    </div>
            </Grid>

            <Grid item lg={4} md={4} sm={6} xs={12} className="D6_Team_Grid2">
                <div className="D6_Team_Grid_Div">
                    <div className="D6_Team_Grid_Image_Block">
                        <img className="D6_Team_Grid1_Image" src={Kashish} alt="Person1"/>
                        <div className="D6_AboutUs_Team_Block_Img_Content D6_AboutUs_Team_Block_Img_Content1">
                            <p className="D6_AboutUs_Team_Block_Img_P1">
                                {Dec6_AboutUs_Team_Block1_Img_Desc[0]}
                            </p>
                        </div>
                    </div>
                    <div className="D6_Team_Grid_NameDiv">
                    <h3 className="D6_Team_Grid_NH">
                        {Dec6_AboutUs_Team_Block1_Img_Name_Text[0]}
                    </h3>
                    <h3 className="D6_Team_Grid_DH">
                        {Dec6_AboutUs_Team_Block1_Img_Name_Desig_Text[0]}
                    </h3>
                    </div>
                </div>
            </Grid>

            <Grid item lg={4} md={4} sm={6} xs={12} className="D6_Team_Grid2">
                <div className="D6_Team_Grid_Div">
                    <div className="D6_Team_Grid_Image_Block">
                        <img className="D6_Team_Grid1_Image" src={Nilesh} alt="Person2"/>
                        <div className="D6_AboutUs_Team_Block_Img_Content D6_AboutUs_Team_Block_Img_Content2">
                            <p className="D6_AboutUs_Team_Block_Img_P1">
                                {Dec6_AboutUs_Team_Block1_Img_Desc[1]}
                            </p>
                        </div>
                    </div>
                    <div className="D6_Team_Grid_NameDiv">
                    <h3 className="D6_Team_Grid_NH">
                        {Dec6_AboutUs_Team_Block1_Img_Name_Text[1]}
                    </h3>
                    <h3 className="D6_Team_Grid_DH">
                        {Dec6_AboutUs_Team_Block1_Img_Name_Desig_Text[1]}
                    </h3>
                    </div>
                </div>
            </Grid>

            <Grid item lg={4} md={4} sm={6} xs={12} className="D6_Team_Grid2">
                <div className="D6_Team_Grid_Div">
                    <div className="D6_Team_Grid_Image_Block">
                        <img className="D6_Team_Grid1_Image D6_Team_Grid1_ImageK" src={Karen} alt="Person2"/>
                        <div className="D6_AboutUs_Team_Block_Img_Content D6_AboutUs_Team_Block_Img_Content3">
                            <p className="D6_AboutUs_Team_Block_Img_P1">
                                {Dec6_AboutUs_Team_Block1_Img_Desc[2]}
                            </p>
                        </div>
                    </div>
                    <div className="D6_Team_Grid_NameDiv">
                    <h3 className="D6_Team_Grid_NH">
                        {Dec6_AboutUs_Team_Block1_Img_Name_Text[2]}
                    </h3>
                    <h3 className="D6_Team_Grid_DH">
                        {Dec6_AboutUs_Team_Block1_Img_Name_Desig_Text[2]}
                    </h3>
                    </div>
                </div>
            </Grid>

            <Grid item lg={4} md={4} sm={6} xs={12} className="D6_Team_Grid2 D6_Team_Grid2_Ank">
                <div className="D6_Team_Grid_Div">
                    <div className="D6_Team_Grid_Image_Block2">
                        <img className="D6_Team_Grid2_Image" src={Ankur} alt="Person4"/>
                        <div className="D6_AboutUs_Team_Block2_Img_Content2 D6_AboutUs_Team_Block_Img_Content4">
                            <p className="D6_AboutUs_Team_Block_Img_P1">
                                {Dec6_AboutUs_Team_Block2_Imgs_Desc[0]}
                            </p>
                        </div>
                    </div>
                    <div className="D6_Team_Grid_NameDiv">
                    <h3 className="D6_Team_Grid_NH">
                        {Dec6_AboutUs_Teams_Block2_Imgs_Name_Text[0]}
                    </h3>
                    <h3 className="D6_Team_Grid_DH">
                        {Dec6_AboutUs_Team_Block2_Imgs_Name_Desig_Text[0]}
                    </h3>
                    </div>
                </div>
            </Grid>

            <Grid item lg={4} md={4} sm={6} xs={12} className="D6_Team_Grid2 D6_Team_Grid2_A">
                <div className="D6_Team_Grid_Div D6_Team_Grid_R">
                    <div className="D6_Team_Grid_Image_Block2">
                        <img className="D6_Team_Grid2_Image" src={Rohan} alt="Person5"/>
                        <div className="D6_AboutUs_Team_Block2_Img_Content2         D6_AboutUs_Team_Block_Img_Content4">
                            <p className="D6_AboutUs_Team_Block_Img_P1">
                                {Dec6_AboutUs_Team_Block2_Imgs_Desc[1]}
                            </p>
                        </div>
                    </div>
                    <div className="D6_Team_Grid_NameDiv">
                    <h3 className="D6_Team_Grid_NH">
                        {Dec6_AboutUs_Teams_Block2_Imgs_Name_Text[1]}
                    </h3>
                    <h3 className="D6_Team_Grid_DH">
                        {Dec6_AboutUs_Team_Block2_Imgs_Name_Desig_Text[1]}
                    </h3>
                    </div>
                </div>
            </Grid>

            <Grid item lg={4} md={4} sm={6} xs={12} className="D6_Team_Grid2 D6_Team_Grid2_A">
                <div className="D6_Team_Grid_Div D6_Team_Grid_A">
                    <div className="D6_Team_Grid_Image_Block2">
                        <img className="D6_Team_Grid2_Image" src={Aadya} alt="Person6"/>
                        <div className="D6_AboutUs_Team_Block2_Img_Content2 D6_AboutUs_Team_Block_Img_Content4">
                            <p className="D6_AboutUs_Team_Block_Img_P1">
                                {Dec6_AboutUs_Team_Block2_Imgs_Desc[2]}
                            </p>
                        </div>
                    </div>
                    <div className="D6_Team_Grid_NameDiv">
                    <h3 className="D6_Team_Grid_NH">
                        {Dec6_AboutUs_Teams_Block2_Imgs_Name_Text[2]}
                    </h3>
                    <h3 className="D6_Team_Grid_DH">
                        {Dec6_AboutUs_Team_Block2_Imgs_Name_Desig_Text[2]}
                    </h3>
                    </div>
                </div>
            </Grid>
        </Grid>
        </Container>
    )
}

export default Dec6_TeamGrid
