import React, { useState } from 'react';
import styled from 'styled-components';
import Menu from './Dec6_Menu';
import PMenu from './Dec6_PMenu';
import "../MainCSS.css";
import './Dec6_Hamburger_CSS.css'
import TemporaryDrawer from './dashboard';
/*
const [isTruncated, setIsTruncated] = useState(true);

function toggleIsTruncated() {
  setIsTruncated(!isTruncated);
}*/

const StyledBurger = styled.div`
  width: 2rem;
  height: 2rem;
  position: fixed;
  top: 45px;
 /* right: 30px; */
 left: 30px;
  z-index: 20;
  display: none;  
  font-family: var(--fontfamily);
 /* 
  @media (max-width: 768px) {   */
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
  
  div {
    width: 2rem;
    height: 0.25rem;
    background-color: ${({ open }) => open ? '#cc0000' : '#333'};
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.2s linear;
   
  }

  @media (max-width: 1200px) {
    top: 31px;
    width: 2rem;
    height: 1.7rem;
    div {
      width: 1.7rem;
    }
}
`;

const Dec6_HamBurger = () => {
  const [open, setOpen] = useState(false)
  const Toggle=()=>{
    setOpen(!open)
  }  
  return (
    <>
      <StyledBurger open={open} >
        <div onClick={() => setOpen(!open)}/>
        <div onClick={() => setOpen(!open)}/>
        <div onClick={() => setOpen(!open)}/>
        <TemporaryDrawer Toggle={Toggle} open={open}  />

      </StyledBurger>
      <Menu />
    </>
  )
}
export default Dec6_HamBurger