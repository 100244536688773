import React from 'react';
import './Dec6_ContactUs_4BS_CSS.css';
import {Paper,Container,Box,Grid} from "@material-ui/core";
import {Dec6_ContactUs_4BS_MBlock_Cont_Paper_Box_H_C_Text,Dec6_ContactUs_4BS_MBlock_Cont_Paper_Box_H_NB_Text,Dec6_ContactUs_4BS_MBlock_Cont_Paper_Box_H_O_Text,Dec6_ContactUs_4BS_MBlock_Cont_Paper_Box_H_SM_Text,Dec6_ContactUs_4BS_MBlock_Cont_Paper_Box_H_Text} from '../TextFiles/Dec6_ContactUs_TextFile';

function Dec6_ContactUs_4BS() {
    return(
    /*    <div className="Dec6_ContactUs_4BS_MBlock_BGColor">
        <div className="Dec6_ContactUs_4BS_MBlock">     */
    <Container>
        <Grid container justify="center" alignItems="center" fixed className="Dec6_ContactUs_4BS_MBlock_Grid">
            <Grid item lg={3} sm={6} md={6} xs={12} className="Dec6_ContactUs_4BS_MBlock_Grid1">
            <Container className="Dec6_ContactUs_4BS_MBlock_Cont">
                <Paper elevation={7} square={true} className="Dec6_ContactUs_4BS_MBlock_Cont_Paper">
                    <Box className="Dec6_ContactUs_4BS_MBlock_Cont_Paper_Box">
                        <h3 className="Dec6_ContactUs_4BS_MBlock_Cont_Paper_Box_H">
                            {Dec6_ContactUs_4BS_MBlock_Cont_Paper_Box_H_Text[0]}
                        </h3>
                        <h4 className="Dec6_ContactUs_4BS_MBlock_Cont_Paper_Box_H_SubH1">
                            {Dec6_ContactUs_4BS_MBlock_Cont_Paper_Box_H_O_Text[0]}
                        </h4>
                        <h4 className="Dec6_ContactUs_4BS_MBlock_Cont_Paper_Box_H_SubH2">
                            {Dec6_ContactUs_4BS_MBlock_Cont_Paper_Box_H_O_Text[1]}
                        </h4>
                        <h4 className="Dec6_ContactUs_4BS_MBlock_Cont_Paper_Box_H_SubH2">
                            {Dec6_ContactUs_4BS_MBlock_Cont_Paper_Box_H_O_Text[2]}
                        </h4>
                        <h4 className="Dec6_ContactUs_4BS_MBlock_Cont_Paper_Box_H_SubH2">
                            {Dec6_ContactUs_4BS_MBlock_Cont_Paper_Box_H_O_Text[3]}
                        </h4>
                        <h4 className="Dec6_ContactUs_4BS_MBlock_Cont_Paper_Box_H_SubH2">
                            {Dec6_ContactUs_4BS_MBlock_Cont_Paper_Box_H_O_Text[4]}
                        </h4>

                        <div className="Link1">
                     {/*   {Dec6_ContactUs_4BS_MBlock_Cont_Paper_Box_H_C_Text}    */}
                            <a href="mailto:info@dec6.ca" className="Dec6_ContactUs_4BS_MBlock_Cont_Paper_Box_H_Link4">
                                Email Us
                            </a>  
                            <a href="mailto:info@dec6.ca" className="fa fa-play Dec6_ContactUs_4BS_MBlock_Cont_PlayIcon3 Dec6_ContactUs_4BS_MBlock_Cont_PlayIcon1"/>
                        </div>

                    </Box>
                </Paper>
            </Container>
            </Grid>

            <Grid item lg={3} sm={6} md={6} xs={12} className="Dec6_ContactUs_4BS_MBlock_Grid2">
            <Container className="Dec6_ContactUs_4BS_MBlock_Cont">
                <Paper elevation={7} square={true} className="Dec6_ContactUs_4BS_MBlock_Cont_Paper">
                    <Box className="Dec6_ContactUs_4BS_MBlock_Cont_Paper_Box">
                    
                        <h3 className="Dec6_ContactUs_4BS_MBlock_Cont_Paper_Box_H">
                            {Dec6_ContactUs_4BS_MBlock_Cont_Paper_Box_H_Text[1]}
                        </h3>
                    <div className="Dec6_ContactUs_4BS_MBlock_Cont_Paper_Box_Div1 Dec6_ContactUs_4BS_MBlock_Cont_Paper_Box_H_SubH">
                     {/*   {Dec6_ContactUs_4BS_MBlock_Cont_Paper_Box_H_NB_Text}   */}
                        <a href="mailto:business@dec6.ca" className="Dec6_ContactUs_4BS_MBlock_Cont_Paper_Box_H_Link">{Dec6_ContactUs_4BS_MBlock_Cont_Paper_Box_H_NB_Text}</a> 
                        <a href="mailto:business@dec6.ca" className="fa fa-play Dec6_ContactUs_4BS_MBlock_Cont_PlayIcon1"/>
                    </div>
                    </Box>
                </Paper>
            </Container>
            </Grid>

            <Grid item lg={3} sm={6} md={6} xs={12} className="Dec6_ContactUs_4BS_MBlock_Grid3">
            <Container className="Dec6_ContactUs_4BS_MBlock_Cont">
                <Paper elevation={7} square={true} className="Dec6_ContactUs_4BS_MBlock_Cont_Paper">
                    <Box className="Dec6_ContactUs_4BS_MBlock_Cont_Paper_Box">
                        <h3 className="Dec6_ContactUs_4BS_MBlock_Cont_Paper_Box_H">
                            {Dec6_ContactUs_4BS_MBlock_Cont_Paper_Box_H_Text[2]}
                        </h3>
                        <div className="Dec6_ContactUs_4BS_MBlock_Cont_Paper_Box_Div1 Dec6_ContactUs_4BS_MBlock_Cont_Paper_Box_H_SubH">
                     {/*   {Dec6_ContactUs_4BS_MBlock_Cont_Paper_Box_H_C_Text}    */}
                            <a href="mailto:info@dec6.ca" className="Dec6_ContactUs_4BS_MBlock_Cont_Paper_Box_H_Link">{Dec6_ContactUs_4BS_MBlock_Cont_Paper_Box_H_C_Text}</a>  
                            <a href="mailto:info@dec6.ca" className="fa fa-play Dec6_ContactUs_4BS_MBlock_Cont_PlayIcon1"/>
                        </div>
                    </Box>
                </Paper>
            </Container>
            </Grid>

            <Grid item lg={3} sm={6} md={6} xs={12} className="Dec6_ContactUs_4BS_MBlock_Grid4">
            <Container className="Dec6_ContactUs_4BS_MBlock_Cont">
                <Paper elevation={7} square={true} className="Dec6_ContactUs_4BS_MBlock_Cont_Paper">
                    <Box className="Dec6_ContactUs_4BS_MBlock_Cont_Paper_Box">
                        <h3 className="Dec6_ContactUs_4BS_MBlock_Cont_Paper_Box_H">
                            {Dec6_ContactUs_4BS_MBlock_Cont_Paper_Box_H_Text[3]}
                        </h3>
                        <div className="Dec6_ContactUs_4BS_MBlock_Cont_Paper_Box_Div1 Dec6_ContactUs_4BS_MBlock_Cont_Paper_Box_H_SubH">
                         {/*   <h4 className="Dec6_ContactUs_4BS_MBlock_Cont_Paper_Box_H_SubH">
                                {Dec6_ContactUs_4BS_MBlock_Cont_Paper_Box_H_SM_Text}
                            </h4>  */}
                            {Dec6_ContactUs_4BS_MBlock_Cont_Paper_Box_H_SM_Text}
                            <i className="fa fa-play Dec6_ContactUs_4BS_MBlock_Cont_PlayIcon1"></i>
                        </div>
                        <a href="https://www.facebook.com/dec6consulting/" target="_blank" className="fab fa-facebook-f Dec6_ContactUs_4BS_MBlock_Cont_Paper_Box_H_SubH_Icon1 Dec6_ContactUs_4BS_alink"></a>
                        <a href="https://twitter.com/Dec6Consulting" target="_blank" className="fab fa-twitter Dec6_ContactUs_4BS_alink Dec6_ContactUs_4BS_MBlock_Cont_Paper_Box_H_SubH_Icon2"></a>
                        <a href="https://www.linkedin.com/company/dec6consulting" target="_blank" className="fab fa-linkedin-in Dec6_ContactUs_4BS_alink Dec6_ContactUs_4BS_MBlock_Cont_Paper_Box_H_SubH_Icon3"></a>
                        <a href="https://www.instagram.com/dec6consulting/?igshid=13uejqzwrqm0d" target="_blank" className="fab fa-instagram Dec6_ContactUs_4BS_alink Dec6_ContactUs_4BS_MBlock_Cont_Paper_Box_H_SubH_Icon4"></a> 
                    </Box>
                </Paper>
            </Container>
            </Grid>
        </Grid>
    </Container>
  /*      </div>
        </div>    */
    );
}

export default Dec6_ContactUs_4BS;