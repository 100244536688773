import React from 'react';
import './Dec6_Home_HR_CSS.css';

function Dec6_Home_HR() {
    return(
        <div>
            <hr className="Dec6_Home_HR_Container"></hr>
        </div>
    );
}

export default Dec6_Home_HR;