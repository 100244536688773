import HeaderLine from '../Header_RedLine/Dec6_HeaderRL';
import AboutUs_Navbar from '../AboutUs_Navbar/Dec6_AboutUs_Navbar';
import Navbar from '../Navbar/Dec6_Navbar';
import ResponsiveNavbar from '../ResponsiveNavbar/Dec6_Navbar';
import AboutUs_Purpose from '../AboutUs_PurposeBlock/Dec6_AboutUs_Purpose';
import AboutUs_Vision from '../AboutUs_VisionBlock/Dec6_AboutUs_Vision';
import AboutUs_Mission from '../AboutUs_MissionBlock/Dec6_AboutUs_Mission';
import AboutUs_Team1 from '../AboutUs_TeamGrid_Block/Dec6_TeamGrid';
import AboutUs_HRLine from '../Home_HorizontalLine_Below6BoxG/Dec6_Home_HR';
import AboutUs_SuccessStory from '../AboutUs_SuccessStoryBlock/Dec6_AboutUs_SuccessStory';
import AboveFooter from '../AboveFooter/Dec6_AboveFooter';
import Footer from '../Footer/Dec6_Footer';
import Scroll_To_Top from '../Scroll_To_Top/Dec6_STTop';
import {Helmet} from "react-helmet";

function Dec6_AboutUsPage() {
  return (
      <>
        <Helmet>
            <title>About Us | Dec6 Consulting Pvt Ltd</title>
            <meta name="description" content="Dec6 Consulting Private Limited" />
            <link rel="canonical" href="https://www.dec6.ca/AboutUs" />
        </Helmet>
        <Scroll_To_Top/>
        <HeaderLine/>
        <ResponsiveNavbar/>
        <AboutUs_Purpose/>
        <AboutUs_Vision/>
        <AboutUs_Mission/>
        <AboutUs_Team1/>
        <AboutUs_HRLine/>
        <AboutUs_SuccessStory/>
        <AboveFooter/>
        <Footer/>
      </>
  );
}

export default Dec6_AboutUsPage;