import HeaderLine from '../Header_RedLine/Dec6_HeaderRL';
import Industries_Navbar from '../Industries_Navbar/Dec6_Industries_Navbar';
import Navbar from '../Navbar/Dec6_Navbar';
import ResponsiveNavbar from '../ResponsiveNavbar/Dec6_Navbar';
import Industries_BgImage from '../Industries_BgImage/Dec6_Industries_BgImage';
import Industries_B2ESec from '../Industries_PaperComp_ForB2E/Dec6_Industries_B2ESec';
import BoxGrid1 from '../Home_6Boxes_GridLayout/Dec6_Home_6BoxGL';
import BoxGrid from '../Home_DialogBox_6Boxes_GridLayout/Dec6_DB_6BGL';
import AboveFooter from '../AboveFooter/Dec6_AboveFooter';
import HRLine from '../Home_HorizontalLine_Below6BoxG/Dec6_Home_HR';
import Footer from '../Footer/Dec6_Footer';
import Scroll_To_Top from '../Scroll_To_Top/Dec6_STTop';
import {Helmet} from "react-helmet";

function Dec6_IndustriesPage() {
  return (
      <>
      <div>
          <Helmet>
            <title>Industries | Dec6 Consulting Pvt Ltd</title>
            <meta name="description" content="Dec6 Consulting Private Limited" />
            <link rel="canonical" href="https://www.dec6.ca/Industries" />
          </Helmet>
        <Scroll_To_Top/>
        <HeaderLine/>
        <ResponsiveNavbar/>    
        <Industries_BgImage/>
        <Industries_B2ESec/> 
        <HRLine/>
        <BoxGrid/>
        <AboveFooter/>
        <Footer/>
      </div>
      </>
  );
}

export default Dec6_IndustriesPage;