/* ---------- Contact Us Want to Contact Us Block ----------- */
const Dec6_ContactUs_WTCU_Block_H1_Text = 'Connect with Us'; 

const Dec6_ContactUs_WTCU_Block_H2_Text = 'We would love to hear from you and answer any questions you may have';

export {Dec6_ContactUs_WTCU_Block_H1_Text,Dec6_ContactUs_WTCU_Block_H2_Text};

/* ----------- Contact Us 4 paper Block section Text ---------- */

const Dec6_ContactUs_4BS_MBlock_Cont_Paper_Box_H_Text = ['Office','New Business','Careers','Social Media'];

const Dec6_ContactUs_4BS_MBlock_Cont_Paper_Box_H_O_Text = ['Dec6 Consulting Private Limited','2/62, Ashri Bhawan,','Shivaji Nagar','Gurugram, Haryana','India - 122001'];

const Dec6_ContactUs_4BS_MBlock_Cont_Paper_Box_H_NB_Text = 'Tell us your interest';

const Dec6_ContactUs_4BS_MBlock_Cont_Paper_Box_H_C_Text = 'Take a bold step forward';

const Dec6_ContactUs_4BS_MBlock_Cont_Paper_Box_H_SM_Text = 'Follow Us';

export {Dec6_ContactUs_4BS_MBlock_Cont_Paper_Box_H_C_Text,Dec6_ContactUs_4BS_MBlock_Cont_Paper_Box_H_NB_Text,Dec6_ContactUs_4BS_MBlock_Cont_Paper_Box_H_O_Text,Dec6_ContactUs_4BS_MBlock_Cont_Paper_Box_H_SM_Text,Dec6_ContactUs_4BS_MBlock_Cont_Paper_Box_H_Text};