import React from 'react'
import './Dec6_PrivacyPolicy_CSS.css';
import {Grid} from '@material-ui/core';
import {Dec6_PrivacyPolicy_Text_MH,Dec6_PrivacyPolicy_Text_P,Dec6_PrivacyPolicy_List1_Text,Dec6_PrivacyPolicy_List2_Text,Dec6_PrivacyPolicy_List3_Text} from 
'../TextFiles/Dec6_PrivacyPolicy_TextFile';

const Dec6_PrivacyPolicy = () => {
    return (
        <div className="Dec6_PP_Block">
        <Grid container xs={12} className="Dec6_PrivacyPolicy_Block_Grid">
            <Grid item lg={12} sm={12} md={12} xs={12} className="Dec6_PrivacyPolicy_Block_Grid1">
                <h3 className="Dec6_PrivacyPolicy_Block_MH Dec6_PrivacyPolicy_Block_H">
                    {Dec6_PrivacyPolicy_Text_MH}
                </h3>
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="Dec6_PrivacyPolicy_Block_Grid2">
                <p className="Dec6_PrivacyPolicy_Block_P1 Dec6_PrivacyPolicy_Block_P">
                    {Dec6_PrivacyPolicy_Text_P[0]}
                </p>
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="Dec6_PrivacyPolicy_Block_Grid3">
                <p className="Dec6_PrivacyPolicy_Block_P2 Dec6_PrivacyPolicy_Block_P">
                    {Dec6_PrivacyPolicy_Text_P[1]}
                </p>
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="Dec6_PrivacyPolicy_Block_Grid4" className="Dec6_PrivacyPolicy_Block_Grid_BoldText Dec6_PrivacyPolicy_Block_P">
                {Dec6_PrivacyPolicy_List1_Text[0]}
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="Dec6_PrivacyPolicy_Block_Grid5 Dec6_PrivacyPolicy_Block_P">
                {Dec6_PrivacyPolicy_List2_Text[0]}
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="Dec6_PrivacyPolicy_Block_Grid6 Dec6_PrivacyPolicy_Block_P">
                {Dec6_PrivacyPolicy_Text_P[2]}
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="Dec6_PrivacyPolicy_Block_Grid7 Dec6_PrivacyPolicy_Block_P">
                {Dec6_PrivacyPolicy_List2_Text[1]}
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="Dec6_PrivacyPolicy_Block_Grid8 Dec6_PrivacyPolicy_Block_P">
                {Dec6_PrivacyPolicy_Text_P[3]}
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="Dec6_PrivacyPolicy_Block_Grid9 Dec6_PrivacyPolicy_Block_P">
                {Dec6_PrivacyPolicy_List2_Text[2]}
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="Dec6_PrivacyPolicy_Block_Grid10 Dec6_PrivacyPolicy_Block_P">
                {Dec6_PrivacyPolicy_Text_P[4]}
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="Dec6_PrivacyPolicy_Block_Grid11 Dec6_PrivacyPolicy_Block_P" className="Dec6_PrivacyPolicy_Block_Grid_BoldText">
                {Dec6_PrivacyPolicy_List1_Text[1]}
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="Dec6_PrivacyPolicy_Block_Grid12 Dec6_PrivacyPolicy_Block_P">
                {Dec6_PrivacyPolicy_Text_P[5]}
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="Dec6_PrivacyPolicy_Block_Grid13" className="Dec6_PrivacyPolicy_Block_Grid_BoldText Dec6_PrivacyPolicy_Block_P">
                {Dec6_PrivacyPolicy_List1_Text[2]}
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="Dec6_PrivacyPolicy_Block_Grid14 Dec6_PrivacyPolicy_Block_P">
                {Dec6_PrivacyPolicy_Text_P[6]}
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="Dec6_PrivacyPolicy_Block_Grid15 Dec6_PrivacyPolicy_Block_P" className="Dec6_PrivacyPolicy_Block_Grid_BoldText">
                {Dec6_PrivacyPolicy_List1_Text[3]}
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="Dec6_PrivacyPolicy_Block_Grid16 Dec6_PrivacyPolicy_Block_P">
                {Dec6_PrivacyPolicy_Text_P[7]}
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="Dec6_PrivacyPolicy_Block_Grid17 Dec6_PrivacyPolicy_Block_P" className="Dec6_PrivacyPolicy_Block_Grid_BoldText">
                {Dec6_PrivacyPolicy_List1_Text[4]}
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="Dec6_PrivacyPolicy_Block_Grid18 Dec6_PrivacyPolicy_Block_P">
                {Dec6_PrivacyPolicy_Text_P[8]}
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="Dec6_PrivacyPolicy_Block_Grid19 Dec6_PrivacyPolicy_Block_P">
                {Dec6_PrivacyPolicy_List2_Text[3]}
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="Dec6_PrivacyPolicy_Block_Grid20 Dec6_PrivacyPolicy_Block_P">
                {Dec6_PrivacyPolicy_Text_P[9]}
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="Dec6_PrivacyPolicy_Block_Grid21 Dec6_PrivacyPolicy_Block_P">
                <ul className="Dec6_PrivacyPolicy_Block_Grid_UL">
                    <li>
                        {Dec6_PrivacyPolicy_List3_Text[0]}
                    </li>
                    <li>
                        {Dec6_PrivacyPolicy_List3_Text[1]}
                    </li>
                    <li>
                        {Dec6_PrivacyPolicy_List3_Text[2]}
                    </li>
                    <li>
                        {Dec6_PrivacyPolicy_List3_Text[3]}
                    </li>
                    <li>
                        {Dec6_PrivacyPolicy_List3_Text[4]}
                    </li>
                </ul>
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="Dec6_PrivacyPolicy_Block_Grid22" className="Dec6_PrivacyPolicy_Block_Grid_BoldText Dec6_PrivacyPolicy_Block_P">
                {Dec6_PrivacyPolicy_List1_Text[5]}
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="Dec6_PrivacyPolicy_Block_Grid23 Dec6_PrivacyPolicy_Block_P">
                If you have questions regarding this statement and have any privacy concerns or complaints, you could direct it to our Data Protection Office on our <a className="Dec6_PP_Grid_Link" href="mailto:dpo@dec6.ca">email </a> .
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="Dec6_PrivacyPolicy_Block_Grid24" className="Dec6_PrivacyPolicy_Block_Grid_BoldText Dec6_PrivacyPolicy_Block_P">
                {Dec6_PrivacyPolicy_List1_Text[6]}
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="Dec6_PrivacyPolicy_Block_Grid25 Dec6_PrivacyPolicy_Block_P">
                {Dec6_PrivacyPolicy_Text_P[11]}
            </Grid>
            <Grid item lg={12} sm={12} md={12} xs={12} className="Dec6_PrivacyPolicy_Block_Grid26 Dec6_PrivacyPolicy_Block_P">
                {Dec6_PrivacyPolicy_Text_P[12]}
            </Grid>
        </Grid>
        </div>

    /*    <div className="Dec6_PrivacyPolicy_Block">
            <h3 className="Dec6_PrivacyPolicy_Block_MH Dec6_PrivacyPolicy_Block_H">
                {Dec6_PrivacyPolicy_Text_MH}
            </h3>
            <p className="Dec6_PrivacyPolicy_Block_P1 Dec6_PrivacyPolicy_Block_P">
                {Dec6_PrivacyPolicy_Text_P[0]}
            </p>
            <p className="Dec6_PrivacyPolicy_Block_P2 Dec6_PrivacyPolicy_Block_P">
                {Dec6_PrivacyPolicy_Text_P[1]}
            </p>

            <ol className="Dec6_PrivacyPolicy_Block_OL">
                <li className="Dec6_PrivacyPolicy_Block_OL_List">
                    {Dec6_PrivacyPolicy_List1_Text[0]}
                </li>
                <ul className="Dec6_PrivacyPolicy_Block_UL">
                    <li className="Dec6_PrivacyPolicy_Block_UL_List">
                        {Dec6_PrivacyPolicy_List2_Text[0]}
                    </li>
                </ul>
                <p className="Dec6_PrivacyPolicy_Block_P3 Dec6_PrivacyPolicy_Block_P">
                    {Dec6_PrivacyPolicy_Text_P[2]}
                </p>
            </ol>
    </div>     */

    )
}

export default Dec6_PrivacyPolicy
