import React,{useEffect} from 'react';
import './Dec6_AboutUs_SuccessStory_CSS.css';
import Gaurav_Pic from '../Images/Gaurav.jpg';
import Liam_Pic from '../Images/Liam.jpg';
import Mark_Pic from '../Images/Person3.jpeg';
import {Dec6_AboutUs_SuccessStory_Block_H_Text,Dec6_AboutUs_SuccessStory_Block_Img_H_Name_Text,Dec6_AboutUs_SuccessStory_Block_P_Text,Dec6_AboutUs_SuccessStory_Block_Img_H_Desig_Text} from '../TextFiles/Dec6_AboutUs_TextFile';
import Aos from 'aos';
import 'aos/dist/aos.css'; 

function Dec6_AboutUs_SuccessStory() {

    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, []); 

    return(
        <div className="Dec6_AboutUs_SuccessStory_Block">
            <h3 data-aos="flip-right"
            data-aos-easing="ease-in-out-cubic" 
            data-aos-once = "true"
                    className="Dec6_AboutUs_SuccessStory_Block_H">
                {Dec6_AboutUs_SuccessStory_Block_H_Text}
            </h3>
            <p data-aos="fade-up"
                    data-aos-delay="150"
                    data-aos-once = "true"
                    className="Dec6_AboutUs_SuccessStory_Block_P1">
                {Dec6_AboutUs_SuccessStory_Block_P_Text[0]}
            </p>
            <p data-aos="fade-down"
                    data-aos-delay="150"
                    data-aos-once = "true"
                     className="Dec6_AboutUs_SuccessStory_Block_P2">
                {Dec6_AboutUs_SuccessStory_Block_P_Text[1]}
            </p>
            <p data-aos="fade-up"
                    data-aos-delay="150"
                    data-aos-once = "true"
                    className="Dec6_AboutUs_SuccessStory_Block_P3">
                {Dec6_AboutUs_SuccessStory_Block_P_Text[2]}
            </p>
            <div data-aos="fade-up"
                    data-aos-delay="200"
                    data-aos-once = "true"
                    className="Dec6_AboutUs_SuccessStory_Block_Img1_Block">
                <img className="Dec6_AboutUs_SuccessStory_Block_Img Dec6_AboutUs_SuccessStory_Block_Img_Mark" src={Mark_Pic} alt="Client 1"></img>
                <h3 className="Dec6_AboutUs_SuccessStory_Block_Img_H_Name_Mark">
                    {Dec6_AboutUs_SuccessStory_Block_Img_H_Name_Text[0]}
                </h3>
                <h3 className="Dec6_AboutUs_SuccessStory_Block_Img_H_Desig">
                    {Dec6_AboutUs_SuccessStory_Block_Img_H_Desig_Text[0]}
                </h3>
            </div>
            <div data-aos="fade-down"
                    data-aos-delay="200"
                    data-aos-once = "true"
                    className="Dec6_AboutUs_SuccessStory_Block_Img2_Block">
                <img className="Dec6_AboutUs_SuccessStory_Block_Img" src={Liam_Pic} alt="Client 1"></img>
                <h3 className="Dec6_AboutUs_SuccessStory_Block_Img_H_Name_Liam">
                    {Dec6_AboutUs_SuccessStory_Block_Img_H_Name_Text[1]}
                </h3>
                <h3 className="Dec6_AboutUs_SuccessStory_Block_Img_H_Desig">
                    {Dec6_AboutUs_SuccessStory_Block_Img_H_Desig_Text[1]}
                </h3>
            </div>
            <div data-aos="fade-up"
                    data-aos-delay="200"
                    data-aos-once = "true"
                    className="Dec6_AboutUs_SuccessStory_Block_Img3_Block">
                <img className="Dec6_AboutUs_SuccessStory_Block_Img" src={Gaurav_Pic} alt="Client 1"></img>
                <h3 className="Dec6_AboutUs_SuccessStory_Block_Img_H_Name">
                    {Dec6_AboutUs_SuccessStory_Block_Img_H_Name_Text[2]}
                </h3>
                <h3 className="Dec6_AboutUs_SuccessStory_Block_Img_H_Desig">
                    {Dec6_AboutUs_SuccessStory_Block_Img_H_Desig_Text[2]}
                </h3>
            </div>
        </div>
    );
}

export default Dec6_AboutUs_SuccessStory;