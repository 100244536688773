import React from 'react';
import '../Header_RedLine/Dec6_HeaderRL_CSS.css';

function Dec6_HeaderRL() {
    return(
        <div className="Dec6_HeaderRL_Container">

        </div>
    );
}

export default Dec6_HeaderRL;