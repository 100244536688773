const Dec6_TAC_MH = 'Terms & Conditions';

const Dec6_TAC_H = ['Published: September 20, 2020',
'1. Terms of Use',
'2. Additional Terms',
'3. Acceptable Use of the Site and Services',
'4. Ownership',
'5. Privacy',
'6. Links',
'7. Changes to the Site or Services',
'8. Termination',
'9. Disclaimer',
'10. Indemnification',
'11. Other Provisions.',
'12. Changes to these Terms'];

const Dec6_TAC_P = ['BY USING THE SITE OR SERVICES, YOU ARE AGREEING TO THESE TERMS.  PLEASE READ THEM CAREFULLY.',

'Unless otherwise stated, the contents of site including but not limited to, the text and images contained herein and their arrangement are the property of DEC6. All trademarks used or referred to in this website are the property of their respective owners.',

'Nothing contained in this site shall be construed as conferring by implication, estoppel, or otherwise, any license or right to any copyright, patent, trademark or other proprietary interest of DEC6 or any third party. This site and the content provided in this site, including, but not limited to, graphic images, audio, video, html code, buttons, and text, may not be copied, reproduced, republished, uploaded, posted, transmitted, or distributed in any way, without the prior written consent of DEC6, except that you may download, display, and print one copy of the materials on any single computer solely for your personal, non-commercial use, provided that you do not modify the material in any way and you keep intact all copyright, trademark, and other proprietary notices.',

'By browsing this site and availing service you agree not to modify, publish, transmit, transfer, sell, reproduce, create derivative work from, distribute, repost, perform, display or in any way commercially exploit any of the Content.',

'You are responsible for your use of the Site and Services, and for any use of the Site or Services made using your account.  Our goal is to create a positive, useful, and safe user experience.  To promote this goal, we prohibit certain kinds of conduct that may be harmful to other users or to us.  When you use the Site or Services, you may not:',

'The DEC6’s services do not automatically capture any specific personal information from you (like name, phone number or e-mail address), that allows us to identify you individually. If you choose to provide us with your personal information, like names or addresses, when you visit our site or register on our products & services, we use it only to fulfill your request for information. For detailed policy on privacy please refer to the detailed Privacy Policy.',

'The Site and Services may contain links to other websites and online resources.  A link to a third party’s website does not mean that we endorse it or that we are affiliated with it.  We are not responsible or liable for any damage or loss related to the use of any third-party website.  You should always read the terms and conditions and privacy policy of a third-party website before using it.',

'We enhance and update the Site and Services often.  We may change or discontinue the Site or Services at any time, with or without notice to you.',

'We reserve the right to not provide the Site or Services to any person.  We also reserve the right to terminate any user’s right to access the Site or Services at any time, in our discretion.  If you violate any of these Terms, your permission to use the Site and Services automatically terminates.',

'YOU USE THE SITE AND SERVICES AT YOUR OWN RISK.  THE SITE AND SERVICES ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS.  TO THE EXTENT PERMITTED BY APPLICABLE LAW, DEC6 AND ITS OFFICERS, EMPLOYEES, DIRECTORS, SHAREHOLDERS, PARENTS, SUBSIDIARIES, AFFILIATES, AGENTS, AND LICENSORS (“AFFILIATES”) DISCLAIMS ALL WARRANTIES, CONDITIONS, AND REPRESENTATIONS OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING THOSE RELATED TO MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT AND THOSE ARISING OUT OF COURSE OF DEALING OR USAGE OF TRADE.',

'IN PARTICULAR, DEC6 AND ITS AFFILIATES MAKE NO REPRESENTATIONS OR WARRANTIES ABOUT THE ACCURACY OR COMPLETENESS OF CONTENT AVAILABLE ON OR THROUGH THE SITE OR SERVICES, OR THE CONTENT OF ANY WEBSITES OR ONLINE SERVICES LINKED TO OR INTEGRATED WITH THE SITE OR SERVICES. DEC6 AND ITS AFFILIATES WILL HAVE NO LIABILITY FOR ANY:  (A) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT; (b) PERSONAL INJURY OR PROPERTY DAMAGE RESULTING FROM YOUR ACCESS TO OR USE OF THE SITE OR SERVICES; (c) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SERVERS OR OF ANY PERSONAL INFORMATION OR USER DATA; (d) ANY INTERRUPTION OF TRANSMISSION TO OR FROM THE SITE OR SERVICES; (e) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED ON OR THROUGH THE SITE OR SERVICES BY ANY THIRD PARTY; OR (f) ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED OR SHARED THROUGH THE SITE OR SERVICES.',

'YOU UNDERSTAND AND AGREE THAT ANY MATERIAL OR INFORMATION DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE SITE OR SERVICES IS DONE AT YOUR OWN RISK AND THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE ARISING FROM DOING SO.  NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM US OR THROUGH THE SITE OR SERVICES WILL CREATE ANY WARRANTY NOT EXPRESSLY MADE IN THESE TERMS.',

'TO THE EXTENT PERMITTED BY LAW, DEC6 AND ITS AFFILIATES WILL NOT BE RESPONSIBLE FOR LOST PROFITS, REVENUES, OR DATA, FINANCIAL LOSSES OR INDIRECT, SPECIAL, CONSEQUENTIAL, EXEMPLARY, OR PUNITIVE DAMAGES.',

'You agree to indemnify and hold harmless DEC6 and its Affiliates from and against any and all claims, costs, proceedings, demands, losses, damages, and expenses (including, without limitation, reasonable attorney’s fees and legal costs) of any kind or nature, arising from or relating to, any actual or alleged breach of these Terms by you or anyone using your account.  If we assume the defense of such a matter, you will reasonably cooperate with us in such defense.',

'From time to time, we may change these Terms and post the revised Terms on the Site itself.  Those changes will go into effect on the Revision Date shown in the revised Terms.  By continuing to use the Site or Services, you are agreeing to the revised Terms.'];

const Dec6_TAC_P_List = ['violate any law or regulation;',
'violate, infringe, or misappropriate other people’s intellectual property, privacy, publicity, or other legal rights;',
'post or share anything that is illegal, abusive, harassing, harmful to reputation, pornographic, indecent, profane, obscene, hateful, racist, or otherwise objectionable;',
'send unsolicited or unauthorized advertising or commercial communications, such as spam;',
'engage in spidering or harvesting, or participate in the use of software, including spyware, designed to collect data from the Site or Services;',
'transmit any viruses or other computer instructions or technological means whose purpose is to disrupt, damage, or interfere with the use of computers or related systems;',
'stalk, harass, or harm another individual;',
'impersonate any person or entity or perform any other similar fraudulent activity, such as phishing;',
'use any means to scrape or crawl any Web pages contained in the Site;',
'attempt to circumvent any technological measure implemented by us or any of our providers or any other third party (including another user) to protect the Site or Services;',
'attempt to decipher, decompile, disassemble, or reverse engineer any of the software or other underlying code used to provide the Site or Services; or',
'advocate, encourage, or assist any third party in doing any of the foregoing.'];

const Dec6_TAC_P_List1 = ['These Terms will be governed by and construed in accordance with the laws of the State of Haryana, India without giving effect to any conflict of laws rules or provisions.',
'You agree that any action of whatever nature arising from or relating to these Terms, the Site, or Services will be filed only in the courts located in Gurugram, Haryana.  You consent and submit to the personal jurisdiction of such courts for the purposes of any such action.',
'If any provision of these Terms is found to be unlawful or unenforceable, then that provision will be deemed severable from these Terms and will not affect the enforceability of any other provisions.',
'The failure by us to enforce any right or provision of these Terms will not prevent us from enforcing such right or provision in the future.',
'We may assign our rights and obligations under these Terms, including in connection with a merger, acquisition, sale of assets or equity, or by operation of law.'];

export {Dec6_TAC_MH,Dec6_TAC_H,Dec6_TAC_P,Dec6_TAC_P_List,Dec6_TAC_P_List1};