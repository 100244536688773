import React from 'react';
import './Dec6_JoinUs_ECS2_CSS.css';
import {Paper,Container,Box} from "@material-ui/core";

function Dec6_JoinUs_ECS2() {
    return(
    /*    <div className="Dec6_JoinUs_ECS2_Block">
            <Container className="Dec6_JoinUs_ECS_Block_Cont1">
                <Paper elevation={7} className="Dec6_JoinUs_ECS_Block_Cont1_Paper">
                    <Box className="Dec6_JoinUs_ECS_Block_Cont1_Paper_Box">
                        <h3 className="Dec6_JoinUs_ECS_Block_Cont1_Paper_Box_H">
                            Explore Career Section
                        </h3>
                    </Box>
                </Paper>
            </Container>
        </div>   */

        <div className="Dec6_JoinUs_ECS2_Block">
            <h3 className="Dec6_JoinUs_ECS_Block_Cont1_Paper_Box_H">
                Explore career areas
            </h3>
        </div>
    );
}

export default Dec6_JoinUs_ECS2;