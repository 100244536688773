import React from "react";
// import { makeStyles } from "@mui/styles";
import { Grid, Container, Typography } from "@material-ui/core";

import "../../MainCSS.css";
import "./Blog3_cat.css"
const Blog3_cat = () => {
  return (
    <div>
    
      <Container className="D6_ADS_ContBlog3">
        <Grid
          container
          justify="center"
          alignItems="center"
          fixed
          className="D6_ADS_GridBlog3"
        >
          <Grid item sm={6} xs={12} className="D6_ADS_Grid1Blog3">
            <div className="D6_ADS_G1_DivBlog3">
            
     {/* <h3 className="D6_ADS_G1_DivH" style={D6_ADS_G1_DivH}>
                AbFam RadianZ
              </h3> */}
              <div className='D6_SL_WS_Grid2_HDivBlog3'>
               
               <Typography component="h3" className='D6_SL_WS_Grid2_Div_H1Blog3'>ARTIFICIAL INTELLIGENCE(AI)</Typography>
           </div>
 <Typography           component="p"
 className="D6_ADS_G1_DivPBlog3_B">
Artificial intelligence (AI) is a wide-ranging branch of computer science concerned with building smart machines capable of performing tasks that typically require human intelligence. </Typography>
 <Typography           component="p"
 className="D6_ADS_G1_DivPBlog3_B">
Artificial intelligence is a theory and development of computer systems that can perform tasks that normally require human intelligence. Speech recognition, decision-making, visual perception, for example, are features of human intelligence that artificial intelligence may possess.  </Typography>
  

<Typography           component="p"
 className="D6_ADS_G1_DivPBlog3_B">
The field was founded on the assumption that human intelligence "can be so precisely described that a machine can be made to simulate it". This raises philosophical arguments about the mind and the ethics of creating artificial beings endowed with human-like intelligence. These issues have been explored by myth, fiction, and philosophy since antiquity. Science fiction and futurology have also suggested that, with its enormous potential and power, AI may become an existential risk to humanity.
</Typography>  
             
  
 </div>
          </Grid>

          <Grid item xs={12} sm={6} className="D6_ADS_Grid1Blog1">
            {/* <div className="D6_ADS_G1_DivBlog1" >
              <img src={SkinImage} alt="Image" className="D6_ADS_ImgBlog1" />
            </div> */}
          </Grid>

      
      
        </Grid>

      </Container>
    </div>
  );
};

export default Blog3_cat;
