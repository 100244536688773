/* -------- Why Choose Us Block -------- */
const Dec6_Services_WCU_Cont_H_Text = 'Why choose us?';

const Dec6_Services_WCU_Cont_P_Text = 'For Dec6, it is fundamental to generate confidence and security in each of our customers, they are the motivation for study, innovation and constant technological changes to improve day by day in our products and business solutions. ';

export {Dec6_Services_WCU_Cont_H_Text,Dec6_Services_WCU_Cont_P_Text};

/* --------- 4 Paper Component Block Text ---------- */
const Dec6_Services_4PCB_Cont_Typ_H_Text = ['IT Consulting','Software Engineering','Cyber Security','Quality Assurance'];

const Dec6_Services_4PCB_Cont_Typ_P_Text = ['Our consulting practice is designed for clients looking for bespoke IT services under a business plan or a consumer plan. Dec6 consultants work out to the precise requirements for our clients & deliver a best in class service to build long term relationships. At Dec6, we focus to every detail of your IT needs.',
'Our Engineering COE, provides wide range of services from idea conception to operational service. At Dec6, Engineers build masterclass IT products for our esteemed enterprise clients & individual consumer needs. Our engineering unit is core to any application development, including creative design.',
'Cyber security has becomes an essential element of Digital World, with growing demand of IT enabled services cyber threats are imminent. Our specialised cyber security team, provides full range of services like vulnerability assessment, security testing, Data Privacy & Protection, IAM, etc.',
'Quality assurance team ensures the quality of our services & products. We specialise in security domain & test our products on latest security benchmarks. QA is key to maintain the quality of our products & services in this digital world. At Dec6, we strive to make this world secure.'];

export {Dec6_Services_4PCB_Cont_Typ_H_Text,Dec6_Services_4PCB_Cont_Typ_P_Text};

/* --------- Services Next Block Below 4 Paper Components ---------- */
const Dec6_Services_NBBPC_Block_H_Text = ['Our Services','An integrated set of people & tools to build, launch, and accelerate your business'];

const Dec6_Services_NBBPC_Block_P_Text = ['Get high-performance hosting, application development, plus a comprehensive toolkit to supercharge your business and help you launch faster. With','Dec6 Consulting,','you get expert support from real developers, best-in-class security, and the most innovative technology to host and manage your business & websites.'];

export {Dec6_Services_NBBPC_Block_H_Text,Dec6_Services_NBBPC_Block_P_Text};

/* ---------- Services 6 Grid Paper Component Block ------------ */
const Dec6_Services_GPC_Cont1_Typg_H_Text = ['IT Consulting','Digital','Cyber Security','Product Innovation','Software Engineering','Insights'];

const Dec6_Services_GPC_Cont1_List_ITCons_Text = ['New Business Innovation','IT Strategy','Program Management & Delivery'];

const Dec6_Services_GPC_Cont1_List_Digi_Text = ['Digital Transformation','Artificial Intelligence/Machine Learning','Blockchain'];

const Dec6_Services_GPC_Cont1_List_CybSec_Text = ['Vulnerability Assessment','Penetration Testing','Digital Forensics'];

const Dec6_Services_GPC_Cont1_List_ProdInnov_Text = ['Product Design','System Design & Architecture','Enterprise App Development'];

const Dec6_Services_GPC_Cont1_List_SoftEng_Text = ['Web App Development','Mobile Enabled Solutions','Creative/Graphic Design'];

const Dec6_Services_GPC_Cont1_List_Ins_Text = ['Advanced Analytics','Data Strategy','Data & AI powered Transformation'];

export {Dec6_Services_GPC_Cont1_Typg_H_Text,Dec6_Services_GPC_Cont1_List_ITCons_Text,Dec6_Services_GPC_Cont1_List_CybSec_Text,Dec6_Services_GPC_Cont1_List_Digi_Text,Dec6_Services_GPC_Cont1_List_Ins_Text,Dec6_Services_GPC_Cont1_List_ProdInnov_Text,Dec6_Services_GPC_Cont1_List_SoftEng_Text};
